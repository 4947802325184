import { render } from 'react-dom'
import * as THREE from 'three'
import React, { forwardRef, useState, useEffect, useMemo, useRef, Suspense } from 'react'
import { Canvas, Dom, extend, useLoader, useThree, useFrame } from '@react-three/fiber'
import { Html, Instances, Instance, ScrollControls, Scroll, useGLTF, useScroll, OrbitControls, Stats } from '@react-three/drei'
import Lottie from 'lottie-react';
import animationData from '../lotties/wpb-logo';
import ScrollWindow from "../components/ScrollWindow";



import { Block, useBlock } from '../blocks'
import lerp from 'lerp'


import YouTube from 'react-youtube';
import Slides from "../components/Slides";
import SlidesOther from "../components/SlidesOther";
import BookCarousel from "../components/BookCarousel";
import BookCarouselOther from "../components/BookCarouselOther";


import InViewMonitor from 'react-inview-monitor';
import { Link, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Deck from "../components/Deck";

import ContentGrid from "../components/ContentGrid";
import ActivityPanel from "../components/ActivityPanel";
//import { useMousePosition } from "../components/useMousePosition";
//import { shuffleArr } from "../utils"
import NewImprovedWindow from 'react-new-improved-window'


import imgs from "../data";

import {
  useAudioStore,
  useSceneStore,
} from '../store'

import useStore from '../store/scroll'

import { useSpring, Spring } from '@react-spring/core'
import { a } from '@react-spring/three'
import diamondUrl from "../assets/star.glb"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"

import logo from '../assets/waypastlogo.png';

//import LogoBug from '../components/LogoBug';
//import BackButton from '../components/BackButton';

import { useRoutes, A } from "hookrouter";

//import './styles.scss'

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const centeredStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  height: "100%"
};
/*
const YouTubeVideoArr = [
  ['CHV8ni_qJl8',`Learn to Make Tie Dye Masks with Hara and Jordyn`],
  ['w2h3t-NqHto',`Do the Way Past Mad Dance with Jordyn and Hara`],
  ['y4Wgahb0ysA',`Sing a Song Like the 76ers Sixth Man`],
  ['OZU1nrEB9GA',`Check out our Book Song Challenge 2020 winners!`],
  //['nEBSj-ilp5Y', `Book Song Challenge 2020 Announcement`],
  ['Rpvj-3my8jM',`Stuck at Home #6 Avery Quiet Ship`],
  ['GffDA4XJQ-k',`Story Series: Penny Wish`],
  ['On6QGqwK7Vo',`Stuck at Home #5 Chloe & Hara Driveway Birthday`],
  ['ZXXAS_onTgY',`Stuck at Home #4: Sage Makes MadCakes`],
  ['aL8A-IsaL6M',`Stuck at Home #3: Card Games`],
  ['T0A32oBnL1U',`Stuck at Home #2 with Kevin & Boba`],
  ['4KP6Q588tRg',`Stuck at Home #1 with Jordyn & Hara`],
  ['iqEkNAunt9o',`Saul's Tips for How to Get Past Your Mad`],
  ['SPz4n5Eson4',`Saul's Book Review`],
  ['ObVDR_neAc8',`Abigail Activity`],
  ['1qr9WwxR72k',`Abigail's tips on how to get past your Way Past Mad.`],
];
const ytShuffleArr = shuffleArr(YouTubeVideoArr);
*/


function Dolly({ mode, tracker, spiralGrid, books1,books2, factor}) {
  const { top, zoom } = useSceneStore(state => state)

  const {sections, panelActive} = useSceneStore(state => state)
  const { setPanelActive } = useSceneStore(state => state.actions)
  const scrollPosition = useStore(state => state.scrollPos)
 
  const { size, viewport, gl, scene, camera, clock } = useThree()
  const aspect = size.height / viewport.height
  const mobile = window.innerWidth < 700;

  const data = useScroll()
 // console.log('data', data)




  useFrame(() => {

   // console.log('ht', viewport.height);

    // data.offset = current scroll position, between 0 and 1, dampened
    // data.delta = current delta, between 0 and 1, dampened

    // Will be 0 when the scrollbar is at the starting position,
    // then increase to 1 until 1 / 3 of the scroll distance is reached
    const a = data.range(0, 1 / 3)
  //  console.log('a is', a)
    // Will start increasing when 1 / 3 of the scroll distance is reached,
    // and reach 1 when it reaches 2 / 3rds.
    const b = data.range(1 / 3, 1 / 3)
        // Same as above but with a margin of 0.1 on both ends
    const c = data.range(1 / 3, 1 / 3, 0.1)
    // Will move between 0-1-0 for the selected range
   // const d = data.curve(1 / 3, 1 / 3)
    // Same as above, but with a margin of 0.1 on both ends
    const d = data.curve(1 / 3, 1 / 3, 0.1)
    // Returns true if the offset is in range and false if it isn't
   // const e = data.visible(2 / 3, 1 / 3)
    const showingBooks1 = data.visible(4 / 7, 1 / 7)
    const showingBooks2 = data.visible(5 / 7, 1 / 7)

   // console.log('book2 is', showingBooks2)
    // The visible function can also receive a margin
    const f = data.visible(2 / 3, 1 / 3, 0.1)


    const spiralY = spiralGrid.current.position.y
    const spiralR = spiralGrid.current.rotation.y;

    if (mode === 'scroll'){

      if (spiralGrid.current){
        
        const nTop = scrollPosition*720;//-100;
        spiralGrid.current.rotation.y = lerp(spiralR, (-nTop / 60) * 1, 0.1)
        spiralGrid.current.position.y = lerp(spiralY, scrollPosition*(sections*100)-60, 0.1)
      // spiralGrid.current.rotation.y = lerp(spiralR, (-nTop / 60) * 1, 0.1)
        //spiralGrid.current.position.y = lerp(spiralY, (nTop / zoom) * 1, 0.1)
      }

    }else{
      spiralGrid.current.rotation.y = lerp(spiralR, 0, 0.1)

      /// need to adjust this based on number of rows??? screen width
      const adj = (mobile) ? 600 : 300;
      spiralGrid.current.position.y = lerp(spiralY, - 30 + ( adj * data.offset), 0.1)
    }

/*

    if (mode === 'scroll'){
      // console.log('cury', curY)
      if (scrollPos>95){
        bkgd.current.position.y = lerp(curY, 0, 0.1)
      } else if (scrollPos>70){
   
       // bkgd.current.position.y = (mobile) ? lerp(curY, tracker.current.parent.position.y -600, 0.1) : lerp(curY, tracker.current.parent.position.y -600 -47, 0.1)
        bkgd.current.position.y = (mobile) ? lerp(curY, tracker.current.parent.position.y -600, 0.1) : lerp(curY, tracker.current.parent.position.y -675, 0.1)
      } else if (scrollPos>50){
   
        // bkgd.current.position.y = (mobile) ? lerp(curY, tracker.current.parent.position.y -600, 0.1) : lerp(curY, tracker.current.parent.position.y -600 -47, 0.1)
         bkgd.current.position.y = (mobile) ? lerp(curY, tracker.current.parent.position.y -600, 0.1) : lerp(curY, tracker.current.parent.position.y -540, 0.1)
         bkgd2.current.position.y = (mobile) ? lerp(curY, tracker.current.parent.position.y -600, 0.1) : lerp(curY, tracker.current.parent.position.y -540, 0.1)
   
      }else{
          bkgd.current.position.y = lerp(curY, (curTop / zoom) * factor, 0.1)
      }
        
      if (carousel.current){
        const spiralY = carousel.current.position.y
        const curR = carousel.current.rotation.y;
        const nTop = curTop-100;
        carousel.current.rotation.y = lerp(curR, (-nTop / 60) * 1, 0.1)
        carousel.current.position.y = lerp(spiralY, (nTop / zoom) * 1, 0.1)
      }
    }else{
      const spiralY = carousel.current.position.y
      const curR = carousel.current.rotation.y;
      const nTop = curTop-40;
      carousel.current.rotation.y = lerp(curR, 0, 0.1)
      carousel.current.position.y = lerp(spiralY,nTop, 0.1)
    }

*/
  })

  return null
}


const getRadian = function(degrees) {
  return (degrees * Math.PI) / 180;
};


function Stars({vheight, colorArr, currentColor}) {

  const { bkgdColor } = useSceneStore(state => state)
 

  const { size, viewport, gl, scene, camera, clock } = useThree()
  const model = useRef()
  //const gltf = useLoader(GLTFLoader, diamondUrl)
  const mobile = window.innerWidth < 700;

  //const { nodes, materials } = useLoader(GLTFLoader, diamondUrl)

  const { nodes, materials } = useGLTF('/assets/star.glb');

  //console.log('nodes', nodes)
/*
const { ...props2 } = useSpring({
  'material-color': 'purple',//currentColor,
  config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 }
})
*/

  // Create random position data
  const dummy = useMemo(() => new THREE.Object3D(), [])
  const stars = useMemo(
    () =>
      new Array(80).fill().map((_, i) => ({
        position: [i < 5 ? 0 : viewport.width / 2 - Math.random() * viewport.width, vheight/8 - Math.random() * vheight/4, i < 5 ? 26 :  Math.random() * 100 - 100],
        factor: 0.01 + Math.random(),
       
        direction: Math.random() < 0.5 ? -1 : 1,
        rotation: [Math.sin(Math.random()) * Math.PI, Math.sin(Math.random()) * Math.PI, Math.cos(Math.random()) * Math.PI]
      })),
    []
  )

  // Render-loop
  useFrame(() => {
    // Update instanced stars
    
    stars.forEach((data, i) => {
      const t = clock.getElapsedTime()
      data.position[1] -= (data.factor / 5) * data.direction
      if (data.direction === 1 ? data.position[1] < -5000 : data.position[1] > 5000)
        data.position = [i < 5 ? 0 : viewport.width / 2 - Math.random() * viewport.width, 5000 * data.direction, data.position[2]]
      const { position, rotation, factor } = data
      const zpos = (mobile) ? 30 : 20;
      dummy.position.set(position[0], position[1], position[2]-zpos)
      dummy.rotation.set(rotation[0] + t * factor, rotation[1] + t * factor, rotation[2] + t * factor)
     // dummy.scale.set(1 + factor, 1 + factor, 1 + factor)
      dummy.scale.set(factor+5, factor+5, factor+5)
      dummy.updateMatrix()
      model.current.setMatrixAt(i, dummy.matrix)
    })
    model.current.instanceMatrix.needsUpdate = true
   
    // Render cube with refraction material to screen
    camera.layers.set(0)
    gl.render(scene, camera)
    
   
  }, 1)

  return (
    <instancedMesh ref={model} args={[null, null, stars.length]} >
     {/* <bufferGeometry dispose={false} attach="geometry" {...gltf.__$[1].geometry} /> */}
      <bufferGeometry dispose={false} attach="geometry" {...nodes.Star.geometry} />
    
      <a.meshStandardMaterial attach="material" roughness={1} color={bkgdColor}/>
    </instancedMesh>
  )
}

const BookGroup = forwardRef((props, ref) => {
  
  const mode = useSceneStore(state => state.mode)
  const sectionActive = useStore(state => state.sectionActive)
  

  const { viewport } = useThree()

  const bookSpring =  useSpring({
    //loop: { reverse: true },
   // from: { position: [-10, -sectionActive * viewport.height , 0] },
    to: { position: [
      //(sectionActive === 5) ? - 1000 : 0, 
      0,
      (sectionActive === 4 || sectionActive === 6 ) ? 
        -sectionActive * viewport.height : 
        (sectionActive === 5) ? -4 * viewport.height : 0 , 
          0] }
  });

  useFrame((state) => {
   // const t = state.clock.getElapsedTime() + random * 10000
  })


  return (
    <a.group 
      ref={ref}
      position={bookSpring.position}
     >
       {mode != 'grid' &&
    
     <BookCarousel 
     //  trigger={'stack'} 
     //  introCallback={introCallback}
     // bookIndex={bookIndex}
     // setBookIndex={(idx) => setBookIndex(idx)}
       />

       
   }
   
   {/* <Stars vheight={5000} /> */}
   
  
     </a.group>
  )
});


export default function CanvasScene() {

  const { cursorActive, youTubeArr, featured, modalState, domRef, scrollRef, events, mode, pages, top, scrollPos, zoom, panel, sections, bookIndex, bookOtherIndex, contentLayout, bkgdColor, loaded, bookState, showBug, shuffled, character, topic, talk, panelActive} = useSceneStore(state => state)
  const { setCursorActive, setFeatured, setModalState, setDomRef, setEvents,setMode,setScene, setScrollPos, setBookState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setBookOtherIndex,setLoaded } = useSceneStore(state => state.actions)
  const { playSound } = useAudioStore(state => state.actions)
  
  const [fullMenuVisible, setFullMenuVisible] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const suspended = useAudioStore(state => state.suspended)

 // const [events, setEvents] = useState()
  const [getElement, setGetElement] = useState(null)
  //const [modalState, setModalState] = useState(false)
  const [defaultHeight, setDefaultHeight] = useState(null)
  //const [featured, setFeatured] = useState(null)
  //const domContent = useRef()
  //const scrollArea = useRef()
  const spiralRef = useRef()
  //const spacerRef = useRef()
  const gridRef = useRef()
  //const booksPanelRef = useRef()
  const bookGroupRef = useRef()
  const bookGroup2Ref = useRef()
  const markerRef = useRef()
  const marker2Ref = useRef()
  const timerRef = useRef();

  const scrollPosRef = useRef(0);

  const [active, setActive] = useState(0)

  const [activeBook, setActiveBook] = useState(0)



  const scrollPosition = useStore(state => state.scrollPos)
  const sectionActive = useStore(state => state.sectionActive)


  const mobile = window.innerWidth < 700;
  const mobileSectionHeight = 70;


  let containmentDOMRect = getElement
      ? getElement
      : null;


  const opts = {
    height: (mobile) ? '234' :'480',
    width: (mobile) ? '416' :'854',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    //  autoplay: 1,
    },
  };

  const colorArr =[
    '#e6613e', //red
    '#4295CF', //blue
    '#17AF88', // green
    '#8378A3', //purple
  ]

  const h2Styles = {
    fontSize: "82px",
     color: "orange",
      backgroundColor: "black",
    // padding: "16px 32px"
  };

  const { spring, setYSpring } = useSpring({
    position: [0,(active >1) ? 30 : 100 ,0],
    config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 },
  });
  // interpolate values from commong spring
  //const ypos = spring.to([0, 1], [0, -500]);

  const winHeight = window.innerHeight/2;
const halfHeight = 133;//winHeight/2;


  const bookSpring =  useSpring({
    loop: { reverse: true },
    from: { position: [-10, -sectionActive * halfHeight , 0] },
    to: { position: [10, -sectionActive * halfHeight, 0] }
  });
  
  /*
  = useSpring({
    position: [0, 0,0],
    config: { mass: 5, tension: 400, friction: 50, precision: 0.0001 },
  });
  */

  useEffect(() => {

   // console.log(scrollArea.current)
    //setDefaultHeight(scrollArea.current.scrollHeight);
   // setDomRef(domContent)

   console.log('Suspended canvasscrne', suspended)
  // if (!suspended) playSound('welcome')
    
  }, [suspended]);


  function introCallback(){
   // alert('start callback')
    const timer = setTimeout(() => {
     // alert('4 seconds')
      setLoaded(true)
      scrollToFeature();
    }, 3000);
    timerRef.current = timer;
  }

  function isMobile(){
    return (mobile)? false : true;
  }

  function setCursor(msg, boo){
    setCursorActive(boo)
    setTopic(msg)
  }

  
  

 // const { canvasWidth, canvasHeight, aspect } = useBlock()


  const [filterGrid, setFilterGrid] = useState(null)



  //const position = useMousePosition();

/*
  const springProps = useSpring({
    color: colorArr[panel],
  })

 

 

  function clickHandler(e, val){
    setActive(val)
  }
 */

  function scrollToFeature() {
    // console.log(scrollArea.current.scrollHeight)
     scroll.scrollTo(scrollRef.current.scrollHeight*.175,{
      // duration: 1500,
      // delay: 100,
       smooth: true,
       containerId: 'scroller',
      // offset: 50, // Scrolls to element + 50 pixels down the page
     
     });
   }


  function triggerViewAction(activePanel){
    console.log('view action')
  }

  function triggerBookViewAction(){
   
   // console.log('markerRef', markerRef)
   // console.log('bookGroupRef', bookGroupRef)
  }



  function showFeature(val){
    
    setModalState(true)
    setFeatured(val)
  }

  function closeModal(){
    setModalState(false)
    setFeatured(null)
  }



  return (
    <>
      <Canvas
        colorManagement
        gl={{ alpha: true, antialias: true }}
        camera={{ position: [0, 0, 300], fov: 25, near: 1, far: 10000 }}
       
        onCreated={({ gl, events }) => {
      //    gl.setClearColor('#e6613e')
         // gl.toneMappingExposure = 2.5
         // gl.toneMappingWhitePoint = 1
          setEvents(events)
         // console.log('events', events)
        }}>


      <ambientLight intensity={0.75} />

      <pointLight position={[7, -5, -100]} intensity={1} angle={0.3} penumbra={10} />
     {/* <pointLight position={[1, -1, -5]} intensity={0.5} /> */}
     
  
    <Suspense fallback={<Html><div className="dot-falling"></div></Html>}>


      <group 
            ref={spiralRef}
            position-z={0}
            position-y={-100}>
              
          <ContentGrid 
            ref={gridRef} 
            layout={contentLayout} 
            filter={filterGrid}
            imgs={imgs}
            currentColor={bkgdColor}
            showFeature={(e) => showFeature(e)}
            setSelection={(e) => setCursor(e, (e.length < 1) ? true : false)}/>

    </group>

     
    <Stars vheight={5000} />

      
    {loaded &&
      <ScrollControls vertical damping={10} pages={sections} id="scroller"
              // className={`scrollArea ${(mode==='grid') ? "grid-mode" : ""}`}
              >
                
              <Dolly 
              mode={mode} 
             // tracker={markerRef} 
              spiralGrid={spiralRef} 
              books1={bookGroupRef} 
              books2={bookGroup2Ref} 
             // scrollPos={scrollPos} 
              factor={2}
              />
      
              <Scroll>
               
                <>
                  <BookGroup ref={bookGroupRef}/>
          
               
                  <group ref={bookGroup2Ref}>
                    {mode != 'grid' &&
                    <BookCarouselOther />
                 }
                  </group>
                  </>
              
              </Scroll>
        
                <ScrollWindow />
          
      </ScrollControls>
}

      </Suspense>
      
      

}

{/*
<Stats
  showPanel={0} // Start-up panel (default=0)
  className="stats" // Optional className to add to the stats container dom element

/>
*/}


      </Canvas>

{/*
      <div className="scroller">{scrollPosition}
      <br />

      <br />
      {sectionActive}
      </div>
*/}
      

    </>
  )
}


const Demo = ({setShowPopup, showPopup}) => (
  <NewImprovedWindow url="https://wpb-draw.netlify.app/"

  onOpen = {() => {
    console.log("open")

  }}

  onUnload = {() => {
    console.log('unload')
    setShowPopup(false)
  }}
/*
      onUnload = {() => {
        console.log("unload")
        if (showPopup === false){
         setShowPopup(false)
        // alert('unload')
        }
      }}
      */
      onBlock = {() => {
        alert('blocked https://espn.com')
        setShowPopup(false)
      }}
      center="screen"
      title="Draw Your Own Dogs"
     
  
  >

  </NewImprovedWindow>
)