
import React, { useState, useEffect } from 'react';
import {
    useSceneStore,
  } from '../store'
  import { useHistory } from "react-router-dom";



const CloseButton = ({toggleOverlay, goBack = true, ...props}) => {

    const history = useHistory();
    const { setCursorActive, setFeatured, setModalState, setDomRef, setEvents,setMode,setScene, setScrollPos, setBookState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setBookOtherIndex,setLoaded } = useSceneStore(state => state.actions)


    function setCursor(msg, boo){
        setCursorActive(boo)
        setTopic(msg)
      }


    return (
        <button 
        onMouseEnter={() => setCursor('', false)} 
        onMouseLeave={() => setCursor('', true)} 

        onClick={() => {
          toggleOverlay();
          if (goBack) history.goBack();
        }} 
        className="close-btn clickable"
        
        >
          X
        </button>
    )
}


export default CloseButton;