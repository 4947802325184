// src/components/Overlay.js

import React, { useState, useEffect } from 'react';
import {
  useSceneStore,

} from '../store'
import GridItem from '../components/GridItem';
import CloseButton from '../components/CloseButton';





const PostersOverlay = () => {
 
  const { isCategoryOverlayVisible} = useSceneStore(state => state)
  const { setIsCategoryOverlayVisible} = useSceneStore(state => state.actions)


  
  useEffect(() => {
    setIsCategoryOverlayVisible(true);
      
  }, []);

  const toggleOverlay = () => {

    setIsCategoryOverlayVisible(false);
  };



  return (
    <div className={`overlay posters-overlay ${isCategoryOverlayVisible ? 'active' : ''}`}>
        <CloseButton
            toggleOverlay={toggleOverlay}
        />
   
        
     
        {/* <A href="/fun-and-games" className="close-btn" onClick={toggleOverlay}>X</A> */}
         <div className="overlay-content">
          <div className='overlay-headline'>
            <h2>Choose a Poster</h2>
          </div>
          <div className="grid-content">

            

          <GridItem 
                  title="Power Poses"
                  image="/assets/images/PowerPoses_poster-thumb.jpg"
                  url="/assets/images/pdf/PowerPoses_poster.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Show You're Sorry"
                  image="/assets/images/WayPastSorry-Poster-poster.jpg"
                  url="/assets/images/pdf/WayPastSorry-Poster.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="You Are The Strongest Thing"
                  image="/assets/images/TheStrongestThingPoster-thumb.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/TheStrongestThingPoster.pdf"
                  target="_blank"
                />
            
          </div>
        </div>
      </div>

  );
};

export default PostersOverlay;