import React, { useState, useEffect, useCallback, useRef, useFrame } from 'react'
import {navigate} from 'hookrouter';
import imgs from "../data";

import Modal from "../components/Modal";
import {usePath} from 'hookrouter';

import {
  useAudioStore,
  useSceneStore,
} from '../store'




export default function Featured({media}){

  const { featured,  modalState } = useSceneStore(state => state);
  const { setModalState,setFeatured } = useSceneStore(state => state.actions)
  const [mediaType, setMediaType] = useState();

  const location = usePath();

  useEffect (() => {


      let newArray = imgs.filter(function (el) {
        return el.content.includes(media)
      })

    //  setPdfUrl(newArray[0].content)


  }, [])


 

  useEffect(() => {
    console.log('Featured on load, media = '+media)
  //  console.log('fetured is '+featured)
    if (featured === null){
      if (media.includes(".pdf")){
        const name = media.replace(/\.[^/.]+$/, "")
        console.log('name', name)
        let newArray = imgs.filter(function (el) {
          console.log('-- el', el)
          return el.content.includes(name)
        })
        console.log(newArray)
        setFeatured(newArray[0].content)
/*
        if (media.includes("http")){
          setFeatured(media)
        }else{
          setFeatured('/assets/images/pdf/'+media)
        }
        */
      }else{
        setFeatured('https://youtu.be/'+media)
      }
    //alert('featured '+media);
      setModalState(true)
      
    }
  }, [])

  

  function closeModal(){
    
    //console.log(window)
   // console.log('featured', featured)
    const currentPath = location;
    if (currentPath.includes('/video')){
      navigate('/videos');
    }else if (currentPath.includes('/fun')){
      navigate('/fun-and-games');
    }else{
      navigate('/');
    }
   /*
    if(window.history.length > 1 && document.referrer.indexOf(window.location.host) !== -1) {
      console.log('go back history')
        window.history.back();
      }else {
        console.log('go back home')
        //navigate('/');
      }
      */
    setModalState(false)
    setFeatured(null)
   // window.history.go(-1);
  }
  

  return(
    <Modal modalState={modalState} onChange={closeModal} content={featured}/>
      
  )
}