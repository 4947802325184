import { debug } from "../config"
//import { mountStoreDevtool } from 'simple-zustand-devtools';

const makeMiddleware = (label, override=false) => {
  return config => (set, get, api) => config(args => {
    if (debug.log || override) { console.log(`Applying ${label}`, args) }
    set(args)
    if (debug.log || override) { console.log(`New ${label} state`, get()) }
  }, get, api)
}

/*
const environment = process.env.TARGET_BUCKET || 'localdev';
if(environment !== 'staging' || environment !== 'production'){
  //mountStoreDevtool('StoreHBO', api);
}
*/

export default makeMiddleware