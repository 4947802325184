import * as THREE from 'three'
import React, { useRef,useEffect, useState } from 'react'
import { useSprings, a } from '@react-spring/three'
import { useThree } from '@react-three/fiber'


import Book from '../Book'
import {
  useSceneStore,
} from '../store'

import useStore from '../store/scroll'
/*
//import './styles.css'
const imgArr =[
  'shame-cover-wrap.jpg',
  'mean-cover-wrap.jpg',
  'embarrassed-cover-wrap.jpg',
  'sorry-cover-wrap.jpg',
  'afraid-cover-wrap.jpg',
  'lonely-cover-wrap.jpg',
  'jealous-cover-wrap.jpg',
  'sad-cover-wrap.jpg',
  'worried-cover-wrap.jpg',
  'mad-cover-wrap.jpg',
  

]
imgArr.reverse();

const number = imgArr.length;
const adjustedNum = number -1;
*/
const colors = ['#A2CCB6', '#FCEEB5', '#EE786E', '#e0feff', 'lightpink', 'lightblue','#e0feff', '#e0feff', '#e0feff']
//const books =['JealousModel','SadModel','WorriedModel', 'MadModel',];
//const books =['MadModel','WorriedModel','SadModel','JealousModel', 'MadModel', 'Book'];
const winHeight = window.innerHeight/2;
const halfHeight = winHeight/4;
const mobile = window.innerWidth < 700;

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function Axes() {
  const { camera, viewport } = useThree(); // Get the camera and viewport
  const distanceFromCamera = 50; // Distance from the camera to the axes helper

  // Calculate the width in Three.js coordinates
  const widthInThreeJS = (viewport.width / camera.aspect) * distanceFromCamera;

  return (
    <axesHelper args={[widthInThreeJS]} /> // Set the scale of the AxesHelper
  );
}

function FullScreenBox() {
  const { viewport } = useThree(); // Get the viewport dimensions

  // The width and height of the box should match the viewport dimensions
  const width = viewport.width; // Width of the viewable area
  const height = viewport.height; // Height of the viewable area

  return (
    <mesh position={[0, 0, 0]}>
      <boxGeometry args={[width*.9, height*.9, 1]} /> {/* Create a box with width and height */}
      <meshStandardMaterial color="orange" transparent={true} opacity={0.5}/>
    </mesh>
  );
}

function Content({ data, imgArr, movement, cycleBooks, introCallback }) {




  return data.map((d, index) => {

    return(
      <a.group key={index} {...movement[index]} onClick={cycleBooks}>
        <Book rotation={[Math.PI / 2, 0, 0]} url={"assets/images/"+imgArr[index]}/>
      </a.group>
    )

    })
}


export default function BookCarousel({
 // trigger,
  introCallback,
 // bookIndex, 
//  setBookIndex, 
  ...props}) {
  //const [bookIndex, setBookIndex] = useState(3)
 // const [triggerState, setTriggerState] = useState(trigger);
  //const { bookState } = useSceneStore(state => state)

  const { setBookIndex, setBookState} = useSceneStore(state => state.actions)
  const { bookState, bookIndex, totalBooks, bookArr } = useSceneStore(state => state)

  const imgArr = bookArr;
  const number = imgArr.length;
  const adjustedNum = number -1;

  const data = new Array(number).fill().map((i) => {
    return {
      color: colors[i],
      args: [30, 40, 2]
    }
  })



  const sectionActive = useStore(state => state.sectionActive)
  const {width, height} = useThree((state) => state.viewport)

  const { camera, viewport } = useThree(); // Get the camera and viewport
  const distanceFromCamera = 10;//camera.position.z;//300; // Distance from the camera to the objects

  // Calculate the width in Three.js coordinates
  const widthInThreeJS = (viewport.width / camera.aspect) * distanceFromCamera;

  console.log('cam z ='+ camera.position.z)
  console.log('Width in Three.js coordinates:', widthInThreeJS);

  

  const xoffset = (mobile)? 3 : 10;

  const zoffset = (mobile)? 140 : 220;


  const idx = number;
  const desktopXpos = (-1* xoffset)  - (idx*adjustedNum) - (adjustedNum-idx)




const random = (i, width) => {

  console.log('RANDOM---------', i)

 // console.log('width='+width+", height="+height);
  
  const mobile = window.innerWidth < 800;
  const r = Math.random()
  const range = 30;
  const leftEdge = -(width*.925) / 2; 
  const numberOfBooks = 10;
  const ratio = height/width;
/*
  const radius = width/2; // Radius of the circular path
  // const [angle, setAngle] = useState(0); // Initial angle
   const angle = 0;
 
   const currentAngle = angle + (i * (2 * Math.PI)) / numberOfBooks; // Calculate angle for each book
     
   
 
   return {
     position: [
       radius * Math.cos(currentAngle), // X position
       0, // Y position (flat)
       radius * Math.sin(currentAngle) - radius/4, // Z position
     ],
     scale: [1, 1, 1],
     opacity: 1,
     rotation: [0, 0, 0],
   }
 
 */

  const vspread = (ratio > 1) ? (height*.9)/3 : height/10
  const hspread = (ratio > 1) ? .8 : .65


  return {
    position: [
      leftEdge + ((width*.95) / numberOfBooks * hspread) * i,
    //  leftEdge + (i*(width / numberOfBooks) ) * ((10-i)/100),// + (i*(width / numberOfBooks))/20,
      (i === (numberOfBooks-1)) ? 
        (ratio>1) ? -vspread :  -16 
      : -15 + getRandomInt(-vspread, vspread),
      i*(width / numberOfBooks) -10
      

    ],
    // color: colors[Math.round(Math.random() * (colors.length - 1))],
    scale: [1, 1, 1],
    opacity: 1,
    rotation: [0, THREE.Math.degToRad(getRandomInt(-30, 30) ), THREE.Math.degToRad(getRandomInt(-20, 20) )]
  }
  
/*
  return {
   // position: [(i*10) - Math.random() * 50, 0 , i * 40 - 30],
    position: [
      //(mobile) ? (i*adjustedNum) - width/3 : (i*18) - width/2.5, 

      (mobile) ? 
       // (i*adjustedNum) - width/3 
        getRandomInt(-width/3, width/3)
        : 
      //  (i*(width/adjustedNum)) - width/2 - ((width/19)*i) + width/20,  // magical numbers
//Math.log(i + 1) * (width / 10),
leftEdge + (width / numberOfBooks*0.6) * i,
    //  (mobile) ? (-10 *i) + 25   : (Math.random()*20)-20 , 
      (mobile) ? 
          (-1 * (height- 30)/number *i) + height/2 - 25   
        : 
        -15,
       // getRandomInt(-20, 20) - 10 , 
     // (mobile) ? i * 20 - 15 : i * 35 - 30
     // (mobile) ? i * 20 - 15 : i*15 - 10
    i*(width / numberOfBooks) - 25
    ],
    // color: colors[Math.round(Math.random() * (colors.length - 1))],
    scale: [1, 1, 1],
    opacity: 1,
    rotation: [0, THREE.Math.degToRad(getRandomInt(-30, 30) ), THREE.Math.degToRad(getRandomInt(-20, 20) )]
  }
    */
}

const leftEdge = -(width*.9) / 2; 
const numberOfBooks = 10;

const [springs, set] = useSprings(number, (i) => ({
  from: {
    position: [(i*20) - Math.random() * 60, -halfHeight, i * 40],
   
    // color: colors[Math.round(Math.random() * (colors.length - 1))],
    scale: [1.5, 1.5, 1.5],
    opacity: 1,
    rotation: [THREE.Math.degToRad(Math.round(Math.random()) * -80), THREE.Math.degToRad(Math.round(Math.random()) * 30), THREE.Math.degToRad(Math.round(Math.random()) * 45)]
  },
  to: {
  //  position: [(i*15) - Math.random() * 50, (i*15) - Math.random() * 50 , i * 50],
   // position: [(i*15) - Math.random() * 50, (i*15) , i * 30],
 //   position: [(i*15), (i*15) , i * 30],
    position:[
      leftEdge + (width / numberOfBooks*0.6) * i,
    -15,
    i*(width / numberOfBooks) - 25
    ],
    // color: colors[Math.round(Math.random() * (colors.length - 1))],
    scale: [1, 1, 1],
    opacity: 1,
    rotation: [THREE.Math.degToRad(Math.round(Math.random()) * 30), THREE.Math.degToRad(Math.round(Math.random()) * 10), THREE.Math.degToRad(Math.round(Math.random()-.3) * 85)]
  },
  config: { mass: 100, tension: 10, friction: 20 },
  onStart: () => {

   // introCallback();
    //  console.log('did it')
  }
}))

  const [count, setCount] = useState(adjustedNum -1);
  // 👇 look here
  const prevCount = usePrevious(count)

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {

    console.log("::::: useEffect", sectionActive)

    if (sectionActive === 0){
      relaunch(width);
    }else if (sectionActive === 4 || sectionActive === 5){
      setBookState('stacked')
    stepBookCarousel(number)
    setCount(adjustedNum)
    setBookIndex(adjustedNum)
    
    

    //  assemble();
   // }else if (sectionActive === 5){
      //scatterAll()
    }else if (sectionActive === 6){
      alignAll();
    }
    
  }, [sectionActive, width]);

  useEffect(() => {

    if (bookState !== 'float'){
      console.log('bookINDEX=', bookIndex)
     stepBookCarousel(count)
     setCount(bookIndex)
    }
    
    
  }, [bookIndex])

  function resizeWin(){
    console.log(width)
  }

  useEffect(() => {
    resizeWin()
  }, [width])
/*
  useEffect(() => {
    console.log('trigger is', trigger)
    //introCallback();
      setTriggerState(trigger)
      if (trigger === 'float'){
        relaunch();
      }else if (trigger === 'stack'){
        assemble();
      }else if (trigger === 'lineup'){
        alignAll();
      }

    
  }, [trigger])
  */

  useEffect(() => {
    console.log('bookState is', bookState)
    //introCallback();
     // setTriggerState(bookState)
      if (bookState === 'float'){
        relaunch(width);
      }
      
      /*
      else if (bookState === 'stack'){
        assemble();
      }else if (bookState === 'lineup'){
        alignAll();
      }else if (bookState === 'scatter'){
        scatterAll();
      }
      */

    
  }, [bookState])

  function relaunch(width) {
    console.log('RELAUNCH---------')

    set((i) => ({ ...random(i, width), config: { mass: 20, tension: 10, friction: 20, precision: 0.0001 }, delay: i * 35 }))
    
  }

  /*

// no need, just trigger stepBookCarousel

  function assemble() {
    console.log('assemble')
    setBookIndex(adjustedNum) 
    setBookState('stack')
    //alert(width)
    
  
    
  

    
    
    set((i) => ({
      ...{
       // position: [i * 6- xoffset, 0, i * 17 + zoffset],
       /// previous
      // position: [
      //  (mobile) ? i*2- 10 : i* adjustedNum - xoffset , 
      //  (mobile) ? 30 : -15, 
      //  (mobile) ? i * 10 + 80   : i * 17 + zoffset  
      ],
      
      
        position: [
        //  (mobile) ? i*2- 10 + 16 - (i*3) - (adjustedNum-i) : i*adjustedNum- xoffset + width/8  - (adjustedNum-i), 
         (mobile) ? 
            i*adjustedNum/4 + xoffset - (idx * adjustedNum/4) 
            : 
            -width/4,
            //i*adjustedNum/2 - xoffset - (idx * adjustedNum/2) - (idx- i)*adjustedNum/2,  /// this has to match the X pos in StepCarousel
     
         // (mobile) ? i*2- 10 : i*adjustedNum - xoffset - (i*adjustedNum - xoffset)/2, 
          (mobile) ? 30 : -15, 
         // (mobile) ? i * 10 + 80   : i * 17 + zoffset  
    
          (mobile) ? 
          i * 15 + zoffset - (idx*15) 
          : 
           i * 20 + zoffset - (idx*20) 
          
        ],
        
        // color: colors[Math.round(Math.random() * (colors.length - 1))],
        opacity: 1,
        scale: [1, 1, 1],
        rotation: [0, THREE.Math.degToRad(30), THREE.Math.degToRad(0)]
      },
      config: { mass: 20, tension: 200, friction: 97, precision: 0.0001 },
      delay: i * 40
    }))
  }
*/
  function alignAll() {
 
    console.log('ALIGN---------')
    setBookState('lineup')
    set((i) => ({
      ...{
        position: [i * 25 - 100, -75, -120],
        opacity: 1,
        // color: colors[Math.round(Math.random() * (colors.length - 1))],
        scale: [1, 1, 1],
        rotation: [0, THREE.Math.degToRad(0), THREE.Math.degToRad(0)]
      },
      config: { mass: 2, tension: 200, friction: 97, precision: 0.0001 },
      delay: i * 100 
    }))
  }

  function scatterAll() {
    console.log('scatter')
    setBookState('scatter')
    set((i) => ({
      ...{
        position: [i * 40 - 200, i * 40, -60],
        opacity: 1,
        // color: colors[Math.round(Math.random() * (colors.length - 1))],
        scale: [1, 1, 1],
        rotation: [0, THREE.Math.degToRad(0), THREE.Math.degToRad(0)]
      },
      config: { mass: 1, tension: 50, friction: 97},
      delay: i * 40
    }))
  }

  function cycleBooks(){
   // if (triggerState === 'stack'){
   // if (bookState === 'stack'){
      console.log('cyclebooks',count)
      if (count >1) {
      // stepBookCarousel(count-1,0)
        setBookIndex(count-1)
      }else{
      //  stepBookCarousel(3, 1)

        setBookIndex(number)
      }
   // }
  }



  function stepBookCarousel(idx, reverse = 0) {

    console.log('STEPBOOKCAROUSEL---------')

    const mobile = window.innerWidth < 700;
   // const xoffset = (mobile)? 15 : 40;
   // const zoffset = (mobile)? 80 : 140;

    
  

    console.log('idx----', idx)

    set((i) =>
      i >= idx
        ? {
            //  if (i===idx) {
            ...{
             // position: reverse === 0 ? [i * 10, 0, i * 20 + 200] : [i * 10 - 60, 0, i * 20 + 80],
              position: [40, (mobile) ? 30 : -15,  500],
              // color: colors[Math.round(Math.random() * (colors.length - 1))],
              scale: [1, 1, 1],
            //  opacity: i === idx ? reverse : 1,
              rotation: [0, THREE.Math.degToRad(30), THREE.Math.degToRad(0)]
            },
            config: { mass: 1, tension: 300, friction: 97 }
            // delay: i * 40

            // }
          }
        : {
          //  if (i===idx) {
           
          ...{
           // position: reverse === 0 ? [i * 10, 0, i * 20 + 200] : [i * 10 - 60, 0, i * 20 + 80],
      //      position: [i * 8 - xoffset, 0, i * 15 + zoffset],

       //     position: [i * 6- xoffset, 0, i * 17 + zoffset],
    //   position: [i*6- xoffset + 36 - (idx*6) - (6-idx) , 0,  i * 17 + zoffset + 72 - (idx*12)  ],
  //  position: [i*3- 10 + 18 - (idx*3) - (6-idx) , 0,  i * 8.5 + zoffset + 36 - (idx*6)  ],
   // position: [i*3- 10  , 0,  i * 10 + 80  ],
    position: [
      //(mobile) ? i*3- 10 + 18 - (idx*3) - (6-idx) : i*6- xoffset + 36 - (idx*6) - (6-idx), 
     // (mobile) ? i*2- 10 + 16 - (idx*3) - (adjustedNum-idx) : i*adjustedNum- xoffset + 36 - (idx*adjustedNum) - (adjustedNum-idx), 
    
    //  (mobile) ? i*2- 10 + 16 - (idx*3) - (adjustedNum-idx) : i*adjustedNum- xoffset + width/8 - (idx*adjustedNum) - (adjustedNum-idx), 
      //x...  
    (mobile) ? 
    i*(adjustedNum/6) - (idx*(adjustedNum/6))  + width/40
        //i*adjustedNum/4 + xoffset - (idx * adjustedNum/4) 
        : 
        i*(adjustedNum/2) - (idx*(adjustedNum/2)) - (adjustedNum - i) - width/18 + (adjustedNum - idx),

      //  i*4 - (idx*4) - (adjustedNum - i)*4 - width/18,


        //-width/4,
    //    i*adjustedNum/2  - (idx*adjustedNum) - (adjustedNum-idx) + width/10, 
       // i*adjustedNum/2  - width/24 - (idx * adjustedNum/2) - (idx- i)*adjustedNum/2,
      //  i*adjustedNum/2 + desktopXpos,
        //y...
      (mobile) ? 30 : -15,  
      //z...
      (mobile) ? 
       // i * 10 + 80 + 72 - (idx*12)  
        i * 15 + zoffset - (idx*15) 
        : 
        i * 20 + 200 - (idx*20) 
       // i * 20 + zoffset - (idx*20) 
      // 0,// i * 20 + zoffset - (idx*20) 
    ],
        //    position: [i * 6 - xoffset + (24/bookIndex )-4 , 0, i * 17 + zoffset + (6*10/(bookIndex+1)) ],
            // color: colors[Math.round(Math.random() * (colors.length - 1))],
            scale: [1, 1, 1],
          //  opacity: i === idx ? reverse : 1,
            rotation: [0, THREE.Math.degToRad(30), THREE.Math.degToRad(0)]
          },
          config: { mass: 20, tension: 200, friction: 100, precision: 0.0001 }
          // delay: i * 40

          // }
        }
    )
  }

  return (
    <>
    {/*
      <Axes /> 
      <FullScreenBox />
*/}
            <Content 
              movement={springs} 
              cycleBooks={cycleBooks}
              imgArr={imgArr}
              data={data}
             // introCallback={introCallback}
              />
  
    </>
  )
}