import React, { useState, useEffect, useCallback, useRef, useFrame } from 'react'
import ResponsiveEmbed from 'react-responsive-embed'

export default function Framed({media}){

    return(
        <div className="framed">
            <div className="fake-button" onClick={() => window.history.back()}></div>
          {/*  <ResponsiveEmbed src={media} /> */}
            <iframe src={media} 
    style={{
        position: 'absolute',
        top:0,
        left:0,
        width:'100%',
        height:'100%'
    }}
    frameBorder="0"
    ></iframe>
        </div>
    )
    

}
