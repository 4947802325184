import React, { useEffect, useState, useRef } from "react";
//import AnimatedCursor from "../components/AnimatedCursor";
import AnimatedCursorX from "../components/AnimatedCursorX";
import StationaryCursorX from "../components/StationaryCursorX";
import {
  useAudioStore,
  useSceneStore,
} from '../store'

export default function Cursor() {


  const { messages, talk, mode, scene, pages,top, sections, scrollPos, bookState, topic, bkgdColor, character, bookIndex, loaded, defaultHeight } = useSceneStore(state => state)
  const { setScene, setScrollPos, setBookState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setLoaded, setDefaultHeight } = useSceneStore(state => state.actions)
  
  const mobile = window.innerWidth < 700;

  return (
    <>
    {(mobile) ?
      <StationaryCursorX /> 

    :
      <AnimatedCursorX />  

    }
    </>
  )
}