import React, { useState, useEffect, useImperativeHandle } from "react";

import Message from "./Message";

//import "./styles.css";
/*
const messages = [
  "Hey, I'm talking in a speech bubble!",
  "Let's do some fun things together.",
  "Just follow me!"
];

*/
const SpeechBubble = ({ messages, talk, topic }, ref) => {
//const SpeechBubble = ({messages, talk, topic}) => {
  const [currentMessage, setCurrentMessage] = useState(0);
  const [msgArr, setMsgArr] = useState(messages);
  const happyPress = useKeyPress('ArrowRight');

  React.useImperativeHandle(ref, () => ({

    nextMessage: () => {
     // alert('next')
    },
  }));

  useEffect(() => {
   // alert(messages)
    setMsgArr(messages)
  }, [messages]);

  

// Hook
function useKeyPress(targetKey) {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);
  
    // If pressed key is our target key then set to true
    function downHandler({ key }) {
        console.log(key)
      if (key === targetKey) {
        setKeyPressed(true);
        nextMessage();
      }
    }
  
    // If released key is our target key then set to false
    const upHandler = ({ key }) => {
      if (key === targetKey) {
        setKeyPressed(false);
      }
    };
  
    // Add event listeners
    useEffect(() => {
      window.addEventListener('keydown', downHandler);
      window.addEventListener('keyup', upHandler);
      // Remove event listeners on cleanup
      return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
      };
    }, []); // Empty array ensures that effect is only run on mount and unmount
  
    return keyPressed;
  }

  const handleClick = () => {
    nextMessage();
  };

  

  function nextMessage(){
    //  console.log(currentMessage)
     // alert('next')

 
   if (currentMessage < messages.length - 1) {
        setCurrentMessage(currentMessage + 1);
      } else {
       // setCurrentMessage(0);
      }
    
}

useEffect(() => {

  const timer = setInterval(() => {
    nextMessage();
   // console.log("again");
  }, 4000);
  return () => clearInterval(timer);
}, [currentMessage]);

useEffect(() => {
   // console.log('currentMessage updated', currentMessage)
  }, [currentMessage]); 

  return (
    <div className="DialogWindow">

      <Message message={topic} key={talk} />
      {/*
      <Message message={messages[talk]} key={talk} />

      <div onClick={handleClick} className="DialogFooter">
        Next {happyPress && '😊'}
      </div>
      */}
    </div>
  );
};

//export default SpeechBubble;

export default React.forwardRef(SpeechBubble);