import create from "zustand"
import { immer } from "zustand/middleware/immer"

const offset = .25;

const useStore = create(immer((set) => ({
  scrollPos: 0,
  sectionActive: 0,
  sections:7,
  updateScrollPos: (num) => set((state) => { 
    state.scrollPos = num
    state.sectionActive = (num <1) ? Math.floor(num * 7) : 6;
  //  state.sectionActive = (num <1) ? Math.floor(num * 7 - offset) : 6
  }),
})))

export default useStore