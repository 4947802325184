import React, { useEffect, useState, useRef } from "react";
import YouTube from 'react-youtube';
import PdfViewer from './PdfViewer'
import ReactToPrint from "react-to-print";
import {
  useSceneStore,
} from '../store'



const Modal = ({ modalState, content, onChange }) => {
  const node = useRef();
  const docRef = useRef();

  const [open, setOpen] = useState(modalState);
  const [type, setType] = useState(null);
  const [featured, setFeatured] = useState(content);

  const { setCursorActive,  setTopic} = useSceneStore(state => state.actions)


        function setCursor(msg, boo){
          setCursorActive(boo)
          setTopic(msg)
        }

  const mobile = window.innerWidth < 700

  const opts = {
    height: (mobile) ? '234' :'480',
    width: (mobile) ? '416' :'854',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return;
    }
    // outside click
    setOpen(false);
    onChange(false);
  };

  const handleChange = selectedValue => {
  //  onChange(selectedValue);
    setOpen(false);
    
  };
  useEffect(() => {
    setOpen(modalState)
  }, [modalState]);

  useEffect(() => {
    if (content !== null){
      setCursor('', false)
    //console.log(content.includes('.pdf'));
   // console.log('feature: '+ window.location.origin+content)
    }
  }, [content]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    setCursor('', false)

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  function getContent(val){
    
    if(val.includes('.pdf')){
        return (
    
            <PdfViewer className="rounded-corners" url={val}/>
     
        )
    }else{

        const youtube_id = val.substr(val.lastIndexOf('/') + 1);
        return (
            <YouTube videoId={youtube_id}
                opts={opts} 
                className={"rounded-corners"}
            
                // onPlay={(e) => clickHandler(e, true)}
                //onReady={clickHandler} 
                /// onEnd={(e) => clickHandler(e, false)}
                // className={"roundedVideo "+(active ? "playing" : "stopped")}
            />
            )
    }
    


  }

  return (
    <div className="modal-container clickable"
    onMouseMove={() => setCursor('', false)} 
    >
    <div ref={node} className="modal rounded-corners" >
  
      {open && content && (
          
         getContent(content)
        
        )}
     
      
    </div>
    </div>
  );
};

export default Modal;