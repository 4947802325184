// src/components/Overlay.js

import React, { useState, useEffect } from 'react';
import {
  useSceneStore,

} from '../store'
import GridItem from '../components/GridItem';
import CloseButton from '../components/CloseButton';



const MoreFunOverlay = () => {
 
  const { isCategoryOverlayVisible} = useSceneStore(state => state)
  const { setIsCategoryOverlayVisible} = useSceneStore(state => state.actions)

  
  useEffect(() => {
    setIsCategoryOverlayVisible(true);
      
  }, []);

  const toggleOverlay = () => {

    setIsCategoryOverlayVisible(false);
  };



  return (
    <div className={`overlay more-fun-overlay ${isCategoryOverlayVisible ? 'active' : ''}`}>
       <CloseButton
            toggleOverlay={toggleOverlay}
        />
         <div className="overlay-content">
          <div className='overlay-headline'>
            <h2>More Fun</h2>
          </div>
          <div className="grid-content">

            

              <GridItem 
                  title="Mood Story Writing Exercise"
                  image="/assets/images/MoodStory_beginning_worksheet-poster.jpg"
                  url="/assets/images/pdf/MoodStory_beginning_worksheet.pdf"
                  target="_blank"
                  orient="vert"
                />
                <GridItem 
                  title="The Penny Wish Writing Exercise"
                  image="/assets/images/MyPennyWish_worksheet-poster.jpg"
                  url="/assets/images/pdf/MyPennyWish_worksheet.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Story Series Writing Tips: The Penny Wish"
                  image="/assets/images/StorySeries-01.jpg"
                  url="https://youtu.be/GffDA4XJQ-k"
                  // // target="_blank"
                  orient="horiz"
                  type="video"
                />


              <GridItem 
                  title="Saul's Tips for How to Get Past Your Mad"
                  image="/assets/images/saul-waypastmad-tips.png"
                  url="https://youtu.be/iqEkNAunt9o"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />
               
                <GridItem 
                  title="Saul's Way Past Mad Book Review"
                  image="/assets/images/saul-waypastmad-review.png"
                  url="https://youtu.be/SPz4n5Eson4"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />


      
                <GridItem 
                  title="Abigail's Way Past Mad Activity Idea"
                  image="/assets/images/Abigail-Activity.png"
                  url="https://youtu.be/ObVDR_neAc8"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Abigail's Tips for How to Get Past Your Mad"
                  image="/assets/images/Abigail-Tips.png"
                  url="https://youtu.be/1qr9WwxR72k"
                 // target="_blank"
                 orient="horiz"
                 type="video"
                />
                 <GridItem 
                  title="Book Review Worksheet"
                  image="/assets/images/BookReview_worksheet-poster.jpg"
                  url="/assets/images/pdf/BookReview_worksheet.pdf"
                  target="_blank"
                />


              <GridItem 
                  title="Sage's Way Past Worried Book Review"
                  image="/assets/images/sage-review-poster.jpg"
                  url="https://youtu.be/VNmM_yI5a4o"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="London's Way Past Worried Book Review"
                  image="/assets/images/london-review-poster.jpg"
                  url="https://youtu.be/p4CAAoa3xm4"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Saul's Way Past Worried Book Review"
                  image="/assets/images/wpw-saulreview.jpg"
                  url="https://youtu.be/nKMqPRh8k5I"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />



              <GridItem 
                  title="Abigail's Way Past Worried Book Review"
                  image="/assets/images/abigail-review-poster.jpg"
                  url="https://youtu.be/NPPIDzNf2es"
                  // // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Way Past Fun Tie Dye Video"
                  image="/assets/images/tiedye-poster.jpg"
                  url="https://youtu.be/CHV8ni_qJl8"
                  // // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Way Past Fun Tie Dye Patterns"
                  image="/assets/images/TieDyeWorksheet-poster.jpg"
                  url="/assets/images/pdf/TieDyeWorksheet.pdf"
                  // target="_blank"

                />



              <GridItem 
                  title="Way Past Worried Drive Thru Book Launch Party"
                  image="/assets/images/bookparty-poster.jpg"
                  url="https://youtu.be/Vwj5iRifCWM"
                   // target="_blank"
                   orient="horiz"
                   type="video"
                />
                <GridItem 
                  title="Stuck at Home #1: Thank A Helper"
                  image="/assets/images/Stuck-at-Home-01.jpg"
                  url="https://youtu.be/4KP6Q588tRg"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Stuck at Home #2: Create A Pet Game"
                  image="/assets/images/Stuck-at-Home-02.jpg"
                  url="https://youtu.be/T0A32oBnL1U"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />



              <GridItem 
                  title="Stuck at Home #3: Play a Card Game"
                  image="/assets/images/Stuck-at-Home-03.jpg"
                  url="https://youtu.be/aL8A-IsaL6M"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Stuck at Home #4: Make A Mad Cake"
                  image="/assets/images/Stuck-at-Home-04.jpg"
                  url="https://youtu.be/ZXXAS_onTgY"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Stuck at Home #5: Plan a Party"
                  image="/assets/images/Stuck-at-Home-05.jpg"
                  url="https://youtu.be/On6QGqwK7Vo"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />



              <GridItem 
                  title="Stuck at Home #6: Build A Quiet Ship"
                  image="/assets/images/Stuck-at-Home-06.jpg"
                  url="https://youtu.be/Rpvj-3my8jM"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Stuck at Home #7: Sing a Song Like the 76ers Sixth Man"
                  image="/assets/images/Stuck-at-Home-07.jpg"
                  url="https://youtu.be/y4Wgahb0ysA"
                  // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Plan a Party Worksheet"
                  image="/assets/images/party-planning-poster.jpg"
                  url="/assets/images/pdf/party-planning.pdf"
                  target="_blank"
                />


                <GridItem 
                  title="Thank A Helper Worksheet"
                  image="/assets/images/thank-a-helper-poster.jpg"
                  url="/assets/images/pdf/thank-a-helper.pdf"
                  target="_blank"
                />
            
          </div>
        </div>
      </div>

  );
};

export default MoreFunOverlay;