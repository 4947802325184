import React, { useState, useEffect, useMemo, useRef, Suspense } from 'react'
import { Canvas, Dom, extend, useLoader, useThree, useFrame } from '@react-three/fiber'
import * as THREE from 'three';
import { useSpring, useSprings, a } from '@react-spring/three'
import InViewMonitor from 'react-inview-monitor';
import { useRoutes, A } from "hookrouter";

const ActivityPanel = ({
  setCursor,
  panelColor, //purple
  mainImage, //assets/images/drawdogs-600.gif
  mainUrl, ///game/drawdogs
  mainUrlTarget,  //_self
  btnOneUrl, ///game/drawdogs
  btnOneUrlTarget = "_self", 
  btnOneMode = "play",
  btnOneMsg, //Follow along and Draw Your Own Dogs!
  btnTwoUrl, //  /fun/DrawYourOwnDogs_Worksheet.pdf
  btnTwoMsg,//Download the Draw Your Own Dogs activity sheet!
  btnTwoUrlTarget = "_self", 
  btnTwoMode = "print",
  btnThreeUrl,
  btnThreeMsg,
  btnThreeUrlTarget = "_self", 
  btnThreeMode = "print",

  ...props }) => {


    return (
<InViewMonitor
            classNameNotInView='content-holder'
            classNameInView={`content-holder colorize ${panelColor}`}
          //  classNameInView='content-holder colorize purple' // fadeInLeft, or fadeInRight
            toggleClassNameOnInView={true}
            
          >

      <div>
            {/*    <div className="content-holder colorize green"> */}
                 {/* <Deck shuffled={shuffled}/> */} 
                 <div className="image-holder clickable" 
                // onClick={() => setShowPopup(true)}
                 //onClick={ ()=> window.open("/game/drawdogs", "_self")}
                 onClick={ ()=> window.open(mainUrl, mainUrlTarget)}
                 >
                 {/* <img src="/assets/images/drawdogs-600.gif" /> */}
                  <img src={mainImage} />

                  </div>

                 

                  <div className="buttonContainer">
                    {/* <A href="/video/aL8A-IsaL6M" > */}
                    <A 
                   // href="/game/drawdogs"
                    href={(btnOneUrl.includes("http") ? '#'  : btnOneUrl)}
                    //onClick={() => setShowPopup(true)}
                   // onClick={ ()=> window.open("/game/drawdogs", "_blank")}
                    onClick={ ()=> {


                      btnOneUrl.includes("http") ? window.open(btnOneUrl, btnOneUrlTarget) : null;
                    }}
                    >
                    <div 
                     // onMouseEnter={() => setCursor('Follow along and Draw Your Own Dogs!', false)} 
                      onMouseEnter={() => setCursor(btnOneMsg, false)} 
                      onMouseLeave={() => setCursor('', true)} 
                     // onClick={() => showFeature('https://youtu.be/aL8A-IsaL6M') } 
                     // className="roundButton playBtn"
                      className={`roundButton ${btnOneMode}Btn`}
                      
                      />
                    </A>
                    {btnTwoUrl &&
                    <A 
                   // href="/fun/DrawYourOwnDogs_Worksheet.pdf"
                    // href={btnTwoUrl} 
                     href={(btnTwoUrl.includes("http") ? '#'  : btnTwoUrl)}
                     onClick={ ()=> {


                      btnTwoUrl.includes("http") ? window.open(btnTwoUrl, btnTwoUrlTarget) : null;
                    }}
                    >
                    <div
                     // onMouseEnter={() => setCursor('Print your own Deck of Cards!', false)} 
                     // onMouseEnter={() => setCursor('Download the Draw Your Own Dogs activity sheet!', false)} 
                      onMouseEnter={() => setCursor(btnTwoMsg, false)} 
                      onMouseLeave={() => setCursor('', true)} 
                     // className="roundButton printBtn" 
                      className={`roundButton ${btnTwoMode}Btn secondBtn`}
                      />
                    </A>
                    }

                    {btnThreeUrl &&
                         <A 
                         // href="/fun/DrawYourOwnDogs_Worksheet.pdf"
                         //  href={btnThreeUrl} 
                           href={(btnThreeUrl.includes("http") ? '#'  : btnThreeUrl)}
                           onClick={ ()=> {


                            btnThreeUrl.includes("http") ? window.open(btnThreeUrl, btnThreeUrlTarget) : null;
                          }}
                          >
                          <div
                           // onMouseEnter={() => setCursor('Print your own Deck of Cards!', false)} 
                           // onMouseEnter={() => setCursor('Download the Draw Your Own Dogs activity sheet!', false)} 
                            onMouseEnter={() => setCursor(btnThreeMsg, false)} 
                            onMouseLeave={() => setCursor('', true)} 
                            className="roundButton playBtn2" 
                            className={`roundButton ${btnThreeMode}Btn thirdBtn`}
                            
                            />
                          </A>
                    
                    }

                   
                 {/* </div> */}
                 </div>
                  </div>
                  </InViewMonitor>
        );
};
              
export default ActivityPanel;