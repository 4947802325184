import create from 'zustand'
import loggerMiddleware from '../utils/loggerMiddleware'
import { hidden, visibilityChange } from '../utils/visibility'


// Log every time state is changed
const log = loggerMiddleware('Audio')



const useStore = create(log((set, get) => {
  return {
    ctx: null,
    fxLib: {}, // dictionary of buffers
    error: null,
    suspended: true,

    wasWelcomed: false,
    wasWelcomedToVideo: false,
    wasWelcomedToFun: false,
    wasSuspended: false,
    bedVol: 1,
    bedActive: false,
    actions: {
     
      setFxLib(obj) {
        set(state => ({ fxLib: obj }))
      },
      setCtx(ctx) {
        const incoming = { ctx, suspended: false }
        if (ctx.state === 'suspended') { incoming.suspended = true }
        set(state => (incoming))
        ctx.onstatechange = () => {
         // alert('ctx.onstatechange', ctx.onstatechange)
          const { suspended } = get()
          if (suspended && ctx.state !== 'suspended') { set({ suspended: false}) }
        }
        document.addEventListener(visibilityChange, (e) => {

          if (document[hidden]) {
            ctx['masterGain'].gain.value = 0
            ctx.suspend();
          } else {
            ctx['masterGain'].gain.value = 1
            ctx.resume() 
          }
  
        })
      },
      setCtxSuspended(boo) {
        set(state => ({ suspended: boo }))
      },
      setCtxError(obj) {
        set(state => ({ error: obj }))
      },
      setWasSuspended(boo) {
        set(state => ({ wasSuspended: boo }))
      },
      setWasWelcomed(boo) {
        set(state => ({ wasWelcomed: boo }))
      },
      setWasWelcomedToVideo(boo) {
        set(state => ({ wasWelcomedToVideo: boo }))
      },
      setWasWelcomedToFun(boo) {
        set(state => ({ wasWelcomedToFun: boo }))
      },
      startBed() {
        const { ctx, fxLib, suspended, error, bedActive } = get()
        if (bedActive) { return false }
        const buffer = fxLib['WebBed']
        if (!buffer || error) {
          console.error('Could not initialize WebBed')
          return
        }
        if (suspended) { ctx.resume() }
        const source = ctx.createBufferSource()
        source.loop = true
        source.buffer = buffer
        source.connect(ctx['bedGain'])
        source.start(0)
        set(state => ({bedActive: true}))
        return source
      },
      setBedVol(val) {
        set(state => ({ bedVol: val }))
      },

  
      playSound(key, dest='masterGain', cb) {
        console.log('playSound', key, dest, cb )
        const { ctx, fxLib, suspended, error } = get()
        const buffer = fxLib[key]
        if (!buffer || error) { return }
        if (suspended) {
          ctx.resume()
          set(state => ({ suspended: false }))
        }
        const source = ctx.createBufferSource()
        source.buffer = buffer
        source.connect(ctx[dest])
        source.onended = cb
        source.start(0)
        return source
      },
      
      resume() {
        const { ctx } = get()
        ctx.resume()
      }
    }
  }
}))

export default useStore