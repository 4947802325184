//import React from "react";
import React, { useState, useEffect, useMemo, useRef, Suspense } from 'react'
//import { animated,  useSpring } from "react-spring";
import { animated, useTransition, useSpring,useSpringRef, useChain, config } from '@react-spring/web'
import {
  useAudioStore,
  useSceneStore,
} from '../store'
import { useRoutes, A } from "hookrouter";


const NavMobile = () => {

  const { fullMenuVisible} = useSceneStore(state => state)
  const { setFullMenuVisible } = useSceneStore(state => state.actions)
 
  const fullMenuAnimation = useSpring({
    transform: fullMenuVisible ? `translateY(0)` : `translateY(-100%)`,
    opacity: fullMenuVisible ? 1 : 0
  });

  


  function isActive(href){
    return (href === window.location.pathname) ? 'active' : null;
                        
  }

 


  
  return (
      <animated.div className="mobile-menu mobile-menu--full menu-navmobile" style={fullMenuAnimation} >
        <nav>
          <ul className="mobile-menu-list mobile-menu-list--full">
            <li className="mobile-menu-list-item mobile-menu-list-item--full">
              <A href="/" className={isActive("/")}><span>Home</span></A>
            </li>
            <li className="mobile-menu-list-item mobile-menu-list-item--full">
              <A href="/videos" className={isActive("/videos")}><span>Videos</span></A>
            </li>
            <li className="mobile-menu-list-item mobile-menu-list-item--full">
                  <A href="/fun-and-games" className={isActive("/fun-and-games")}><span>Fun & Games</span></A>
            </li>
            <li className="mobile-menu-list-item mobile-menu-list-item--full">
               <A href="/books" className={isActive("/books")}><span>Books</span></A>
            </li>
            <li className="mobile-menu-list-item mobile-menu-list-item--full">
            <A href="/more" className={isActive("/more")}><span>More</span></A>
            </li>
          </ul>
        </nav>
      </animated.div>
    )
  };

export default NavMobile;