import React, { useState, useEffect, useCallback, useRef, useFrame } from 'react'
import lerp from 'lerp'
import {useSpring, animated} from 'react-spring'
import useTimer from "../components/useTimer";
import useTimeout from "../hooks/useTimeout";
import useSound from 'use-sound';

import welcomeSfx from '../resources/audio/welcome.mp3';
import makeMadSfx from '../resources/audio/DontMakeMeMad.mp3';
import pastFunSfx from '../resources/audio/WayPastBooksAreWayPastFun.mp3';
//import welcomeSfx from '../resources/audio/welcome.mp3';

import SpeechBubble from './SpeechBubble'
import CursorCircle from './CursorCircle'
import {
  useAudioStore,
  useSceneStore,
} from '../store'

const audioArr = [
  welcomeSfx,
  makeMadSfx,
  pastFunSfx
]


function useEventListener(eventName, handler, element = document) {
    const savedHandler = React.useRef()
  
    React.useEffect(() => {
      savedHandler.current = handler
    }, [handler])
  
    React.useEffect(() => {
      const isSupported = element && element.addEventListener
      if (!isSupported) return
  
      const eventListener = (event) => savedHandler.current(event)
  
      element.addEventListener(eventName, eventListener)
  
      return () => {
        element.removeEventListener(eventName, eventListener)
      }
    }, [eventName, element])
  }
  
  /**
   * Animated Cursor
   * Replaces the native cursor with a custom animated cursor.
   *
   * @author Stephen Scaff
   */
  function AnimatedCursorX({
    color = '103, 58, 183',
    color2 = '255, 255, 255',
    outerAlpha = 0.8,
    innerSize = 20,
    outerSize = 10,
    outerScale = 5,
    innerScale = 3,
    //messages,
   // topic,
    //talk,
    //currentColor,
   // character
  }) {



    const { timer, isTimerActive, isPaused, handleStart, handlePause, handleResume, handleReset } = useTimer(0)

    const suspended = useAudioStore(state => state.suspended)
 
    const { scrollPos, cursorActive, wordBalloonVisible, scrollRef, mode, pages, top,  zoom, panel, sections, bookIndex, contentLayout, bkgdColor, loaded, bookState, showBug, shuffled, character, topic, talk} = useSceneStore(state => state)
    const { setWordBalloonVisible, setMode,setScene, setScrollPos, setBookState,  setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setLoaded, setTopic} = useSceneStore(state => state.actions)
  
    const [playWelcome, exposedData1] = useSound(welcomeSfx, {
      interrupt: true,
    });
    const [playMad, exposedData2] = useSound(makeMadSfx, {
      interrupt: true,
    });
    const [playFun, exposedData3] = useSound(pastFunSfx, {
      interrupt: true,
    });

    const sfxArr = [
      playWelcome,
      playMad,
      playFun
    ]

    const cursorOuterRef = React.useRef()
    const cursorInnerRef = React.useRef()
    const requestRef = React.useRef();
    const bubbleRef = React.useRef();
    const tailRef = React.useRef();
    const tailShadowRef = React.useRef();
    const previousTimeRef = React.useRef()
    const [coords, setCoords] = React.useState({ x: 0, y: 0 })
    const [isVisible, setIsVisible] = React.useState(true)
    const [isActive, setIsActive] = React.useState(false)
   const [isCursorActive, setIsCursorActive] = React.useState(false) 
    const [speech, setSpeech] = React.useState([])
    const [timerState, setTimerState] = React.useState(false)
    const [isActiveClickable, setIsActiveClickable] = React.useState(false)
    let endX = React.useRef(0)
    let endY = React.useRef(0)

    useEffect(() => {
      // alert('wordBalloonVisible')
      cursorInnerRef.current.style.transform = `scale(${innerScale})`
      
     }, [])

   // let timer;
   useEffect(() => {
    // alert('wordBalloonVisible')
    // console.log('timer',timer)
   }, [timer])

   useEffect(() => {
    // alert('wordBalloonVisible')
      showCursor(cursorActive)
   }, [cursorActive])

    useEffect(() => {
     // alert('wordBalloonVisible')
     // console.log('wordBalloonVisible',wordBalloonVisible)
    }, [wordBalloonVisible])

  
    const { clear, reset } = useTimeout(() => {
      //alert('timeout')
     // setCursorActive(boo)
     if (topic.includes("C'mon")){
      setTopic('Scroll for more...')
     }

    }, 6000)

    function showBalloon(boo){
      if (boo){
    
        cursorOuterRef.current.style.opacity = 1
        if (mode==='grid'){
          // set timer to fade
        }
        /*
        if (timerState) clearTimeout(timer);
        console.log('>>> start timer')
        timer = setTimeout(() => {
          //alert('end timer')
          showBalloon(false)
          setTimerState(false)
          console.log('<<< end timer')
        // console.log("again");
        }, 4000);
        setTimerState(true)
        */
      }else{
       // console.log(cursorOuterRef)
        cursorOuterRef.current.style.opacity = 0
      }
    }

    function showCursor(boo){
      if (cursorInnerRef.current){
    
        if (boo){
      
          cursorInnerRef.current.style.opacity = 1
          if (mode==='grid'){
            // set timer to fade
          }

        }else{
        // console.log(cursorOuterRef)
          cursorInnerRef.current.style.opacity = 0
        }
      }

    }

    function scaleCursor(boo){
      if (boo){
    
        cursorInnerRef.current.style.transform = `scale(${innerScale * .8})`
       
      }else{
        cursorInnerRef.current.style.transform = `scale(${innerScale})`
      }
    }

    useEffect(() => {
      if(cursorOuterRef.current != null && cursorInnerRef.current != null) {
        if (topic.length>0 ) {
         // cursorInnerRef.current.style.opacity = 1
         // cursorOuterRef.current.style.opacity = 1
          showBalloon(true)
        // showBubble();
        } else {
          showBalloon(false)
         // cursorInnerRef.current.style.opacity = 0
         // cursorOuterRef.current.style.opacity = 0;
        
        }
      }
      console.log('cursor: topic is '+topic)
    }, [topic])
  
    const onMouseMove = React.useCallback(({ clientX, clientY }) => {
      setCoords({ x: clientX, y: clientY })
      cursorInnerRef.current.style.top = clientY + 'px'
      cursorInnerRef.current.style.left = clientX + 'px'
     // cursorInnerRef.current.style.top = '100px'//clientY + 'px'
     // cursorInnerRef.current.style.left = '100px'//clientX + 'px'
      endX.current = clientX
      endY.current = clientY
      //console.log('onMouseMove')
    }, [coords])
  
    const animateOuterCursor = React.useCallback(
      (time) => {
        if (previousTimeRef.current !== undefined && tailRef.current && cursorOuterRef.current ) {
          coords.x += (endX.current - coords.x +30) / 8
          if (endY.current <100){
            coords.y += (endY.current - (coords.y-80)) / 8
            tailRef.current.style.transform = "rotate(-50deg) scaleY(-1)";
            tailShadowRef.current.style.transform = "rotate(-50deg) scaleY(-1)";
         
          }else{
            coords.y += (endY.current - (coords.y+120)) / 8
            tailRef.current.style.transform = "rotate(50deg) scaleY(1)";
            tailShadowRef.current.style.transform = "rotate(50deg) scaleY(1)";
       
          }
          
         
          cursorOuterRef.current.style.top = coords.y + 'px'
          cursorOuterRef.current.style.left = coords.x + 'px'
      
        }
        previousTimeRef.current = time
        requestRef.current = requestAnimationFrame(animateOuterCursor)
      },
      [requestRef] // eslint-disable-line
    )

   useEffect(() => {
      requestRef.current = requestAnimationFrame(animateOuterCursor)
    }, [animateOuterCursor])
  
    const onMouseDownX  = useCallback(() => {
      setWordBalloonVisible(!wordBalloonVisible)
      /*
      bubbleRef.current.nextMessage();
      if (topic.length>0) showBubble();
      setIsActive(true)
      */
    }, [])

    const onMouseDown = () => {
     // console.log('isTimerActive',isTimerActive)
    
      //playSfx()
      if (scrollPos < 10 && contentLayout === 'spiral'){
        exposedData1.stop();
        exposedData2.stop();
        exposedData3.stop();
        let rand = Math.floor(Math.random()*3)
      /*  
      if (rand === 0){
          playWelcome();
        }else if (rand === 1){
          playMad();
        }else{
          playFun();
        }
        */
      }
      scaleCursor(true)
     // setWordBalloonVisible(!wordBalloonVisible)
     /*
      if (isTimerActive){
        handleReset();
      }else{
        handleStart();
      }
      */

    }

    const onMouseUp = () => {
      
      scaleCursor(false)
      
    }
   // const onMouseUp    = useCallback(() => setIsActive(false), [])
    const onMouseEnter = useCallback(() => setIsCursorActive(true), [])
    const onMouseLeave = useCallback(() => setIsCursorActive(false), [])

   
  
    useEventListener('mousemove', onMouseMove, document)
    
    useEventListener('mousedown', onMouseDown, document)
    useEventListener('mouseup', onMouseUp, document)
    useEventListener('mouseenter', onMouseEnter, document)
    useEventListener('mouseleave', onMouseLeave, document)
   
    

  /*
    useEffect(() => {
      if (isActive) {
       // cursorInnerRef.current.style.transform = `scale(${innerScale*.3})`;
        cursorInnerRef.current.style.opacity = 0
        
       // cursorOuterRef.current.style.transform = `scale(${outerScale*2})`
      } else {
        cursorInnerRef.current.style.transform = `scale(${innerScale})`
        cursorInnerRef.current.style.opacity = 1
      //  cursorOuterRef.current.style.transform = 'scale(1)'
      }
    }, [innerScale, outerScale, isActive])
  
    useEffect(() => {
      if (isActiveClickable) {
        cursorInnerRef.current.style.transform = `scale(${innerScale * 1.1})`
      //  cursorOuterRef.current.style.transform = `scale(${outerScale * 1.4})`
      }
    }, [innerScale, outerScale, isActiveClickable])
  
    useEffect(() => {
     // console.log('IsVisible', isVisible)
     if (cursorOuterRef.current){
      if (isVisible) {

        cursorInnerRef.current.style.opacity = 1
        cursorOuterRef.current.style.opacity = 1
   
      } else {
     //   cursorInnerRef.current.style.opacity = 0

        cursorOuterRef.current.style.opacity = 0
      }
    }
    }, [isVisible])

    function showBubble(){
      if (timerState) clearTimeout(timer);
      setIsVisible(true);
     // if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        setIsVisible(false);
        setTimerState(false)
       // console.log("again");
      }, 4000);
      setTimerState(true)
    }

    useEffect(() => {
     // console.log('topic', topic)
     //if (topic !== undefined){
      if (cursorOuterRef.current){
        if (topic.length>0) {
          cursorInnerRef.current.style.opacity = 1
          cursorOuterRef.current.style.opacity = 1
         // showBubble();
        } else {
      //   cursorInnerRef.current.style.opacity = 0
          cursorOuterRef.current.style.opacity = 0;
         
        }
      }
     // }

     // console.log('is timer?', timer)
      return () => clearTimeout(timer);
    }, [topic])
  */

   
    useEffect(() => {
     
      const clickables = document.querySelectorAll(
        'a, input[type="submit"], input[type="image"], label[for], select, button, a.link, div.roundButton'
      )
     
     

      const clickables2 = document.querySelectorAll(
        '.clickable'
      )

      console.log('clickables2', clickables2)
      
      //const clickables = document.getElementsByClassName('link roundButton')
   
  
      clickables.forEach((el) => {
       // el.style.cursor = 'none'
     //  console.log(el)
  
        el.addEventListener('mouseover', () => {
          setWordBalloonVisible(false)
          showCursor(false)
          setIsActive(true)
         // setSpeech([el.innerText])
        })
        /*
        el.addEventListener('click', () => {
          setWordBalloonVisible(true)
          setIsActive(true)
          setIsActiveClickable(false)
       
        })
        el.addEventListener('mousedown', () => {
          setIsActiveClickable(true)
     
        })
        el.addEventListener('mouseup', () => {
          setIsActive(true)
        })
        */
        el.addEventListener('mouseout', () => {
          setWordBalloonVisible(true)
          showCursor(true)
          setIsActive(false)
          //setIsActiveClickable(false)
        })
        
      })
  
      return () => {
        clickables.forEach((el) => {
          el.removeEventListener('mouseover', () => {
           // setWordBalloonVisible(true)
           // setIsActive(true)
          })
          /*
          el.removeEventListener('click', () => {
            setWordBalloonVisible(false)
            setIsActive(true)
            setIsActiveClickable(false)
          })
          el.removeEventListener('mousedown', () => {
            setIsActiveClickable(true)
          })
          el.removeEventListener('mouseup', () => {
            setIsActive(true)
          })
           */
          el.removeEventListener('mouseout', () => {
           // setIsActive(false)
           // setIsActiveClickable(false)
          })
         
        })
      }
    }, [])

    useEffect(() => {
      if (!suspended){
        setWordBalloonVisible(true)
        showCursor(true)
        setIsActive(false)
      }
    }, [suspended]);

    useEffect(() => {
      // alert(messages)
     // console.log('get text', speech)
     }, [speech]);

    


  
    const styles = {
      cursor: {
        zIndex: 999999999,
        position: 'fixed',
        opacity: 1,
        pointerEvents: 'none',
        transition: 'opacity 0.15s ease-in-out, transform 0.15s ease-in-out'
      },
      cursorInner: {
        position: 'fixed',
        borderRadius: '50%',
        width: innerSize,
        height: innerSize,
        pointerEvents: 'none',
        backgroundColor: `rgba(${bkgdColor}, 1)`,
        transition: 'opacity 0.15s ease-in-out, transform 0.25s ease-in-out',
        zIndex: 999999999,
      },
      cursorOuter: {
        position: 'fixed',
       // borderRadius: '50%',
        pointerEvents: 'none',
      //  width: outerSize,
      //  height: outerSize,
        width: '300px',
        backgroundColor: `rgba(${color2}, ${outerAlpha})`,
        transition: 'opacity 0.15s ease-in-out, transform 0.15s ease-in-out',
        zIndex: 999999998,
      }
    }

    

    
  
    return (
      <>
      {(wordBalloonVisible && topic.length>1) &&
    
        <div
        ref={cursorOuterRef}
        style={styles.cursorOuter}
        className="word-balloon bubble speech"
      >
       
     {/* <SpeechBubble className="bubble-text" messages={messages} talk={talk}/> */}
      
        <SpeechBubble ref={bubbleRef} className="bubble-text" topic={topic} messages={speech} talk={talk}/> 
      
        <div ref={tailRef} className="tail"></div>
        <div className="balloon-body"></div> 
        <div ref={tailShadowRef} className="tail-shadow"></div>
      </div>

      }
 
      <div
        ref={cursorInnerRef}
        style={styles.cursorInner}
        className="mycursor"
      >
      
      <CursorCircle />  
  
    
       
        </div>
        
      </>
    )
  }
  
  

export default AnimatedCursorX;