import React, { useEffect, useState, useRef } from "react";
import MobileBug from '../components/MobileBug';
import LogoBug from '../components/LogoBug';
import BackButton from '../components/BackButton';
import Timer from "../components/Timer";

import {
  useAudioStore,
  useSceneStore,
} from '../store'
/*
import {
  BrowserRouter as Router,
 // Switch,
  Route,
  NavLink,
  Link
} from "react-router-dom";
*/
import { useRoutes, A } from "hookrouter";

export default function Nav({
  scrollToTop,
  onHomeClick,
  onVideoClick,
  onFunClick,
  onBookClick,
  onMoreClick
}) {

 
  const { contentLayout, showBug, fullMenuVisible, mode, scene, pages,top, sections, scrollPos, bookState, topic, bkgdColor, character, bookIndex, loaded, defaultHeight } = useSceneStore(state => state)
  const { setFullMenuVisible, setScene, setScrollPos, setBookState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setLoaded, setDefaultHeight } = useSceneStore(state => state.actions)
  

  const mobile = window.innerWidth < 700;

  function getDesktopBug(){
    return (
      (contentLayout === 'spiral')? <LogoBug showBug={showBug} currentColor={bkgdColor}/> : <BackButton showBug={true} currentColor={bkgdColor}/>
    )
  }


  function isActive(href){
   
    return (href === window.location.pathname) ? 'active' : null;
                        
  }

  return (
<>
<div className="menubar">
  <A href="/">
    <div className="menu left" style={{ top: '2.55rem' }} >
    {/* <LogoBug showBug={showBug} currentColor={bkgd}/> */}

    {!mobile&&
    
          getDesktopBug()
   }
  
  
 
    </div>
    </A>

{(mobile)?
    
    <div className="menu right">
       {/* <MobileBug />  */}
         
                    <MobileBug 
                      showBug={true}
                      currentColor={bkgdColor} 
                     // setMenu={(fullMenuVisible) => setFullMenuVisible(fullMenuVisible)}
                    //  activate={fullMenuVisible}
                      //setActivate={setFullMenuVisible(!fullMenuVisible)}
                      setActivate={(fullMenuVisible) => setFullMenuVisible(fullMenuVisible)}
                    />
         

    
       
    </div>

:
    <div className="menu right">
   
      <A href="/videos" className={isActive("/videos")} ><span>Videos</span></A>
      <A href="/fun-and-games" className={isActive("/fun-and-games")} ><span>Fun & Games</span></A>
      <A href="/books" className={isActive("/books")} ><span>Books</span></A>
      <A href="/more" className={isActive("/more")} ><span>More</span></A>
      {/*
      <a onClick={onVideoClick}><span>Videos</span></a>
      <a onClick={onFunClick}><span>Fun & Games</span></a>
     
      <a onClick={onBookClick}><span>Books</span></a>
      <a onClick={onMoreClick}><span>More</span></a> 
      */}
    </div>
}
</div>
</>       
  )
};