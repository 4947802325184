import React, { useRef,useEffect, useState } from 'react'
import {navigate} from 'hookrouter';
import {
    useSceneStore,
  } from '../store'




export default function GridItem({
    url = '#',
    image = '/assets/images/thumb-vert.jpg',
    title = 'placeholder',
    target = "_self",
    orient = "vert",
    type = "pdf",
     ...props}) {

        
        const { filterGrid, contentLayout, bkgdColor, loaded, bookState, showBug, shuffled, character, topic, talk} = useSceneStore(state => state)
        const { setCursorActive, setFeatured, setModalState, setDomRef, setEvents,setMode,setScene, setScrollPos, setBookState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setBookOtherIndex,setLoaded } = useSceneStore(state => state.actions)


        function setCursor(msg, boo){
          setCursorActive(boo)
          setTopic(msg)
        }
        
        function loadContent(val, type){
            //alert(val)
            console.log(val)

            if (type === 'video'){
              const n = val.lastIndexOf('/');
              const name = val.substring(n + 1);
              const path = (type === 'video' || val.includes('game')) ? type : 'fun';
            
              if (contentLayout==='grid') {
                if (val.includes('game')){
                  navigate(val);
                }else{
                  navigate('/'+path+'/'+name);
                }
            
              
              // showFeature(val)

                setModalState(true)
                setFeatured(val)
              }
            }else if (type === 'game'){
              window.open(val, "_self");
            }else{
              window.open(val, "_blank", "noreferrer");

            }
        }





        return(
                <a 
                    //href={url} 
                    //target={target}  
                  
                    onMouseEnter={() => setCursor('', false)} 
                    onMouseLeave={() => setCursor('', true)} 
               
                    className={`clickable ${orient}`}
                    >

                    <div className="grid-item"
                        onClick={() => loadContent(url, type)}
                    >
                        <img
                            src={image}
                            alt={title}
                            
                        />
                        <h3>{title}</h3>
                    </div>
                </a>
        )

}