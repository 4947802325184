import { render } from 'react-dom'
import React, { useState, useCallback, useEffect } from 'react'
import { useTransition, animated } from '@react-spring/web'
//import './styles.css'
import {
  useAudioStore,
  useSceneStore,
} from '../store'


const OtherStrongest = ({setHover}) => {
  return (
    <>
      <h2 className="mobile-title">The Strongest Thing</h2>
      <h2 className="availability">Available in Store and Online</h2>
      <p>
      Sera doesn’t always like being at home, where her dad’s anger feels like the strongest thing. School feels better. But when Sera gets into an argument with a friend and finds herself acting like the bully at home, she discovers that the strongest thing isn’t loud or angry at all.
      </p>
      <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Now!</h4>
          <div className="buylink-group" id="buylinks"
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
              <li className="buylink">
                <a className="link" href="https://www.amazon.com/Strongest-Thing-When-Home-Feels/dp/0807531685/ref=sr_1_1?dchild=1&keywords=9780807531686&qid=1632416213&sr=8-1" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/the-strongest-thing-hallee-adelman/1140014475?ean=9780807531686" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/Strongest-Thing/Hallee-Adelman/9780807531686?id=8306084943085" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
              
              
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
      
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/book/the-strongest-thing/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
       
              <li className="buylink">
                <a className="link" href="https://www.target.com/p/the-strongest-thing-by-hallee-adelman-hardcover/-/A-85332467#lnk=sametab" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

const OtherQuietship = ({setHover}) => {
  return (
    <>
      <h2 className="mobile-title">My Quiet Ship</h2>
      <h2 className="availability">Available in Store and Online</h2>
      <p>
      Whenever the yelling in his house starts, Quinn uses his imagination to become Commander of the Quiet Ship. There he pretends to travel far, far away from the yelling sounds. A thoughtful treatment of a difficult topic, this story is for children or families whose home is sometimes filled with sounds that they’d like to quiet down. </p>
      
      
      <div className="all-links">
        <div className="store-links">
          <h4 className="preorder">Order Now!</h4>
          <div className="buylink-group" id="buylinks"
          onMouseEnter={() => setHover(false)} 
          onMouseLeave={() => setHover(true)} >
            <ul>
              <li className="buylink">
                <a className="link" href="https://www.amazon.com/My-Quiet-Ship-Hallee-Adelman/dp/0807567132" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/amazon.png"
                    alt="Amazon"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.barnesandnoble.com/w/my-quiet-ship-hallee-adelman/1128007316?ean=9780807567135" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bn.png"
                    alt="Barnes &amp; Noble"
                  />
                </a>
              </li>
              <li className="buylink">
                <a className="link" href="https://www.booksamillion.com/p/My-Quiet-Ship/Hallee-Adelman/9780807567135?id=7327036160373" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/bam.png"
                    alt="Books-A-Million"
                  />
                </a>
              </li>
            
          
              <li className="buylink">
                <a className="link" href="https://childrensbookworld.net/adelman-assorted-titles/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/cbw.png"
                    alt="Children's Book World"
                  />
                </a>
              </li>
           
              <li className="buylink">
                <a className="link" href="https://www.albertwhitman.com/book/my-quiet-ship/" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/albertwhitman96.png"
                    alt="Albert Whitman"
                  />
                </a>
              </li>
           
               <li className="buylink">
                <a className="link" href="https://www.target.com/p/my-quiet-ship-by-hallee-adelman/-/A-84817800?preselect=84275768#lnk=sametab" target="_blank">
                  <img
                    src="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2019/12/target.png"
                    alt="Target"
                  />
                </a>
              </li>
            </ul>
           
          </div>
          
        </div>
        
      </div>
      <p>
              See More at <a href="https://myquietship.com" target="_blank" className="slide-link">MyQuietShip.com</a>  --  Also Available in Paperback
            </p>
    </>
  )
}

const pages = [

  
  ({ style, setHover }) => <animated.div style={{ ...style}}><OtherQuietship setHover={setHover}/></animated.div>,
  ({ style, setHover }) => <animated.div style={{ ...style}}><OtherStrongest setHover={setHover}/></animated.div>,

]

/*
const pages = {
        WPWorried,
        WPMad,
       // WPSad,
       // WPJealous,
      }
*/
function SlidesOther() {

  const { cursorActive, bookOtherIndex  } = useSceneStore(state => state)
  const { setCursorActive, setBookOtherIndex } = useSceneStore(state => state.actions)
  
  
  const [index, set] = useState(bookOtherIndex)

  const panels = new Array(2)
  const onClick = useCallback(() => set((state) => (state + 1) % 6), [])
 // const transitions = useTransition(index, (p) => p, {
 // const transitions = useTransition(pages[bookIndex], {
  const transitions = useTransition(index, {
    keys: index+'other',
    from: { opacity: 0.5 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    exitBeforeEnter: true
  })


  useEffect(() => {
   // console.log('slides: index:'+index+' , bookindex:'+bookIndex)
     if (bookOtherIndex<2) {
         set(bookOtherIndex)
     }else{
        set(0)
     }
  
   }, [bookOtherIndex])

 

   function setHover(boo){
    // console.log('boo is '+boo)
      setCursorActive(boo);
   }


  return (
    <div className="book-description lefty" 
     // onMouseEnter={() => setHover('', false)} 
     // onMouseLeave={() => setHover('', true)} 
    >
      
       {transitions((styles, i) => {
       const Page = pages[i]
        return <Page key={i+'pg'} style={styles} setHover={(e) => setHover(e)} />
     }
     
     )}
    
    </div>
  )
}

export default SlidesOther