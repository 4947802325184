import React, { useState, useEffect, useMemo, useRef, Suspense } from 'react'
import { Canvas, Dom, extend, useLoader, useThree, useFrame } from '@react-three/fiber'
import * as THREE from 'three';
import { useSpring, useSprings, a } from '@react-spring/three'
import InViewMonitor from 'react-inview-monitor';
import { useRoutes, A } from "hookrouter";

const ActivityPanel2 = ({setCursor, ...props }) => {


    return (
<InViewMonitor
            classNameNotInView='content-holder'
            classNameInView='content-holder colorize blue' // fadeInLeft, or fadeInRight
            toggleClassNameOnInView={true}
            
          >

      <div>
            {/*    <div className="content-holder colorize green"> */}
                 {/* <Deck shuffled={shuffled}/> */} 
                 <div className="image-holder clickable" 
                // onClick={() => setShowPopup(true)}
                // onClick={ ()=> window.open("/game/drawdogs", "_self")}
                 onClick={ ()=> window.open("https://mixed-emotions.waypastbooks.com", "_self")}
                 >
                  <img src="/assets/images/mixed-poster.png" />

                  </div>

                 

                  <div className="buttonContainer">
                    {/* <A href="/video/aL8A-IsaL6M" > */}
                    <A href="/" 
                    //onClick={() => setShowPopup(true)}
                    onClick={ ()=> window.open("https://mixed-emotions.waypastbooks.com", "_self")}
                    >
                    <div 
                      onMouseEnter={() => setCursor('Play the Mixed Emotions Interactive Game!', false)} 
                      onMouseLeave={() => setCursor('', true)} 
                     // onClick={() => showFeature('https://youtu.be/aL8A-IsaL6M') } 
                      className="roundButton playBtn" />
                    </A>
                    <A href="/fun/WayPastBooks_MixedEmotions_Printable_Card_Deck.pdf">
                    <div
                     // onMouseEnter={() => setCursor('Print your own Deck of Cards!', false)} 
                      onMouseEnter={() => setCursor('Or Download the Mixed Emotions Card Games!', false)} 
                      onMouseLeave={() => setCursor('', true)} 
                      className="roundButton printBtn" />
                    </A>

                   
                 {/* </div> */}
                 </div>
                  </div>
                  </InViewMonitor>
        );
};
              
export default ActivityPanel2;