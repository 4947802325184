import React from "react";
import Featured from "./components/Featured";
import Framed from "./components/Framed";
import Overlay from "./components/Overlay";
import BookActivitiesOverlay from "./components/BookActivitiesOverlay";
import GameOverlay from "./components/GameOverlay";
import RecipiesOverlay from "./components/RecipiesOverlay";
import PostersOverlay from "./components/PostersOverlay";
import BscOverlay from "./components/BscOverlay";
import MoreFunOverlay from "./components/MoreFunOverlay";


const routes = {
  "/": () => null,
  "/videos": () => null,
  "/game/drawdogs": () => <Framed media={'https://draw-dogs.waypastbooks.com/'}/>,
  //"/game/mixed-emotions": () => <Framed media={'https://wpb-mixed-emotions.netlify.app/'}/>,
  "/lesson-plans": () => <Overlay />,
  "/category/book-activities": () => <BookActivitiesOverlay />,
  "/category/games": () => <GameOverlay />,
  "/category/recipes": () => <RecipiesOverlay />,
  "/category/posters": () => <PostersOverlay />,
  "/category/book-song-challenge": () => <BscOverlay />,
  "/category/more-fun": () => <MoreFunOverlay />,
  "/fun-and-games": () => null,
  "/books": () => null,
  '/video/:id': ({id}) => <Featured media={id}/>,
  '/fun/:id': ({id}) => <Featured media={id}/>
};

export default routes;