import React, { useEffect, useState, useRef } from "react";
import MobileBug from '../components/MobileBug';
import LogoBug from '../components/LogoBug';
import BackButton from '../components/BackButton';


import {
  useAudioStore,
  useSceneStore,
} from '../store'

import { useRoutes, A } from "hookrouter";

export default function CategoryBar({
  scrollToTop,
  onHomeClick,
  onVideoClick,
  onFunClick,
  onBookClick,
  onMoreClick
}) {

 
  const [expand, setExpand] = useState(false);
  const { bkgdColor} = useSceneStore(state => state)
  const { setCursorActive, setFullMenuVisible, setScene, setScrollPos, setBookState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setLoaded, setDefaultHeight } = useSceneStore(state => state.actions)


  function setCursor(msg, boo){
    setCursorActive(boo)
    setTopic(msg)
  }

  const mobile = window.innerWidth < 700;



  function isActive(href){
   
    return (href === window.location.pathname) ? 'active' : null;
                        
  }

  return (

    <div 
    //className="category-bar clickable"
    className={`category-bar clickable ${expand ? 'is-expanded' : ''}`}
    
    style={{
        background: bkgdColor
    }}
      onMouseEnter={() => setCursor('', false)} 
      onClick={() => setExpand(!expand)} 
      onMouseLeave={() => setCursor('', true)} 

    >

          <div className="category-dropdown">
            <A href="#" className="category-instruction"><span>Browse By Category</span></A>
            <A href="/category/book-activities" ><span>Book Activities</span></A>
            <A href="/category/games" ><span>Games</span></A>
            <A href="/category/recipes"  ><span>Recipes</span></A>
            <A href="/category/posters"  ><span>Posters</span></A>
            <A href="/category/book-song-challenge" ><span>Book Song Challenge</span></A>
            <A href="/category/more-fun" ><span>More Fun</span></A>

          </div>

    </div>
     
  )
};