import React, { useState, useEffect, useMemo, useRef, Suspense } from 'react'
import { Canvas, Dom, extend, useLoader, useThree, useFrame } from '@react-three/fiber'
import * as THREE from 'three';
import { useSpring, useSprings, a } from '@react-spring/three'
//import { Billboard } from 'drei'
import ImagePanel from "./ImagePanel";
import {navigate} from 'hookrouter';

import { useMediaQuery } from 'react-responsive'
import { mediaQueries } from '../config'

import {
  useAudioStore,
  useSceneStore,
} from '../store'

//const number = 36
const panelWidth = 200;
const panelHeight = 115;
const colors = ['#A2CCB6', 'orange', 'lightgreen', '#080820', 'lightpink', 'lightblue']
//const colors = new Array(number).fill().map(() => niceColors[17][Math.floor(Math.random() * 5)])
/*
const textureLoadObj = new THREE.TextureLoader();
const img = textureLoadObj.load('/assets/images/makeaface.png')
*/
const random = (i) => {
  const r = Math.random()
  return {
    position: [100 - Math.random() * 200, 100 - Math.random() * 200, i * 1.5],
    color: colors[i%6]
    // scale: [1 + r * 14, 1 + r * 14, 1],
    // rotation: [0, 0, THREE.Math.degToRad(Math.round(Math.random()) * 45)]
  }
}

const getRadian = function (degrees) {
  return (degrees * Math.PI) / 180
}

function clamp(min, max, val) {
  return Math.min(Math.max(min, +val), max);
}

const spiral = (i, num) => {
  // equidistant points on a spiral
  let theta = 0

  // const datum = data[i];
  const radius = Math.max(1, Math.sqrt(i + 1) * 0.8)
  theta += Math.asin(1 / radius) * 1

  const space = 40

  // datum.x = radius * Math.cos(theta);
  // datum.y = radius * Math.sin(theta);
  // datum.z = 0;
  const x =  Math.sin(getRadian((i % 60) * 1) * space) * 120 //radius;
  const y = i * -space*.5 //(radius/4 + i) * -radius/4;
  const z = Math.cos(getRadian((i % 60) * 1) * space) * 120 //radius
  const rotY = (Math.PI / 4 - 4) * i

  return {
    position: [x, y, z],
    opacity: .3
    //color: colors[i]
    // scale: [1 + r * 14, 1 + r * 14, 1],
    // rotation: [0, 0, THREE.Math.degToRad(Math.round(Math.random()) * 45)]
  }
}




const grid = (i, num, setGridHeight) => {

 
  const mobile = window.innerWidth < 700;

  const numPoints = num; //data.length;
  //const numCols = (mobile) ? 2 : Math.ceil(Math.sqrt(numPoints))
  
       
  const numCols = (mobile) ? 2 : clamp(2, 6, Math.floor((window.innerWidth-200)/panelWidth));//cols;//Math.ceil(Math.sqrt(numPoints))
  const numRows = Math.ceil(numPoints/numCols);

  //const gridHeight = numRows * panelHeight;// + (numRows-1)*space;  

  const col = (i % numCols) - numCols / 2
  const row = Math.floor(i / numCols);// - numRows / 2;
  const total = Math.floor(numPoints / numCols) * numRows;
  const total2 = row *col;





 // numRows * panelHeight + (numRows-1)*space;

  const vspace = (mobile) ? 30 : 30;
  const hspace = (mobile) ? 30 : 40;
  
  //const vertOffset = (mobile) ? 30 : -2*total;
 //const vertOffset = -total*2;
 const vertOffset = (panelHeight- vspace)/2 + 20;
  //const vertOffset = (window.innerHeight- gridHeight)/2;

 // console.log('total', total)
 // console.log('total2', total2)


  const x = col * hspace + hspace / 2 //1.05;
 // const y = (mobile) ? ((row*-1) * space) + 30 :  ((row*-1) * space) - total*2 //1.05;
  const y = ((row*-1) * vspace) + vertOffset; //1.05;
  //const y = ((row*-1) ) + vertOffset; //1.05;
  //const z = (mobile) ? -100 : 0
  const z = 0

  return {
    position: [x, y, z],
    opacity: 1
    //  color: colors[Math.round(Math.random() * (colors.length - 1))]
    // scale: [1 + r * 14, 1 + r * 14, 1],
    // rotation: [0, 0, THREE.Math.degToRad(Math.round(Math.random()) * 45)]
  }
}
/*
const data = new Array(number).fill().map(() => {
  return {
    color: colors[Math.round(Math.random() * (colors.length - 1))],
    args: [10, 10, 1]
  }
})
*/

const Image = ({ type, layout,filter, url, args, ...props }) => {
  const texture = useMemo(() => new THREE.TextureLoader().load('/assets/images/'+url), [url]);

  const { filterGrid, contentLayout, bkgdColor, loaded, bookState, showBug, shuffled, character, topic, talk} = useSceneStore(state => state)
  
  // yuck -- opacity as own spring, which doesn't work. Switching on and off now
  const [opacityState, setOpacityState] = useState(layout)
  const [hoverState, setHoverState] = useState(false);
  const [filterState, setFilterState] = useState(null)


  
  /*
  const { opa } = useSpring({
    opacity: (opacityState === 'grid') ? 1 : .3,
    config: { mass: 100, tension: 50, friction: 50 },
    //config: SPRING_CONFIG,
    // onRest: () => setIdle((state) => !state),
    // onStart: () => setIdle((state) => !state),
  });
  */
 /*
 const scaleSpring  = useSpring({
    scale: (hoverState) ? [args[0]*1.1, args[1]*1.1, args[2]*1.1] : args,
    config: { mass: 100, tension: 50, friction: 50 },
    //config: SPRING_CONFIG,
    // onRest: () => setIdle((state) => !state),
     onStart: () => {console.log('start scaleSpring')},
  } );
*/

/*
  useEffect(() => {
    console.log('hoverstate', hoverState)
   // document.body.style.cursor = hoverState ? 'pointer' : 'move'
  }, [hoverState])
*/

  useEffect(() => {
    /*
    if (layout === 'grid') {
      set((i) => ({ ...grid(i), config: { mass: 1, tension: 150, friction: 50 }, delay: i * 40 }))
    } else if (layout === 'random') {
      set((i) => ({
        ...random(i),
        config: { mass: 100, tension: 50, friction: 50 },
        delay: i * 40
      }))
    } else {
      set((i) => ({ ...spiral(i), config: { mass: 1, tension: 150, friction: 50 }, delay: i * 40 }))
    }
    //updateInstancedMeshMatrices({ mesh: meshRef.current, data });
    */
 /////  setOpacityState(layout)
  }, [layout])

  useEffect(() => {

   setFilterState(filter)
  }, [filter])

  return (
    <a.group {...props}>
    <a.sprite  
      scale={(hoverState ? [args[0]*1.05,args[1]*1.05,args[2]*1.05] : args)}
      //scale={scaleSpring.scale}

      //overridden...
     // onPointerOver={() => setHoverState(true)}
     // onPointerOut={() => setHoverState(false)}
    >
   
      <spriteMaterial attach="material" map={texture} transparent={true} opacity={((contentLayout==='grid' && type === filterState)  || (contentLayout==='grid' && hoverState===true))? 1: 0.3} color={(filterState === type || hoverState===true)? 'white':'#e6613e'}/>
   
    {/*  <planeBufferGeometry attach="geometry" args={args} />
      <meshLambertMaterial attach="material">
        <primitive attach="map" object={texture} />
      </meshLambertMaterial>*/}
    </a.sprite>
    </a.group>
  );
};



const ContentGrid = ({ 
 // layout='grid', 
 // filter, 
 // currentColor, 
  imgs, showFeature, setSelection }, ref) => {
//function ContentGrid({ layout='grid',filter, imgs, showFeature, setSelection }) {

  const { filterGrid, contentLayout, bkgdColor, loaded, bookState, showBug, shuffled, character, topic, talk} = useSceneStore(state => state)
  const { setGridHeight} = useSceneStore(state => state.actions)

  //const { playSound } = useAudioStore(state => state.actions)

  const isMobile = useMediaQuery({ query: mediaQueries.mobile })
  const isTablet = useMediaQuery({ query: mediaQueries.tablet })
  const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

  const [data, setData] = useState([])
  const [timerState, setTimerState] = React.useState(false)

  let timer;


  const [springs, set] = useSprings(imgs.length, (i) => ({
    //from: spiral(i),
    ...spiral(i, imgs.length),
    config: { mass: 1, tension: 150, friction: 50 }
  }))

  useEffect (() => {
    const data = imgs.map((d, id) => { 
      console.log('--------')
      console.log(d.thumb)
      // console.log(id)
        let ratio = [];
        if (d.ratio === 'vert'){
           // ratio = [9, 12, 1] //3:4
            ratio = [18, 24, 1] //3:4
        }else{
           // ratio = [16, 9, 1]
            ratio = [32, 18, 1]
        }

       return { 
         id,
         'ratio': d.ratio,
         'name':d.name,
         'thumb': d.thumb,
         'type': d.type,
         'content': d.content,
          color: colors[Math.round(Math.random() * (colors.length - 1))],
          args: ratio
         
       }
     
     });
     console.log('data', data)
     setData(data)

     resize();
    window.addEventListener("resize", resize)
    return () => {
      window.removeEventListener("resize", resize)
    }
  }, [])

  function resize(){
    if (contentLayout === 'grid') {
      resizeGrid();
    }
  }

  function resizeGrid(){
    const numCols = (isMobile) ? 2 : clamp(2, 6, Math.floor((window.innerWidth-200)/panelWidth));
    //const numCols = Math.floor((window.innerWidth)/panelWidth);//cols;//Math.ceil(Math.sqrt(numPoints))
    const numRows = Math.ceil(imgs.length/numCols);
    //console.log('numCols', window.innerWidth)
    
    const gridHeight = numRows * panelHeight;
    const space = (isMobile) ? 30 : 40;
    const spaceHeight = numRows *space;
    const viewportHeight = Math.ceil(gridHeight/ window.innerHeight);

    //why two times for mobile??
    const padding = (isMobile) ? window.innerHeight*2 : window.innerHeight;

    //setGridHeight(viewportHeight * 120 )
    //setGridHeight(gridHeight +spaceHeight+ window.innerHeight)
    setGridHeight(gridHeight +spaceHeight+ padding)
    set((i) => ({ ...grid(i,imgs.length ), config: { mass: 1, tension: 150, friction: 50 }, delay: i * 40 }))
  }

  useEffect(() => {
    if (contentLayout === 'grid') {
      //set((i) => ({ ...grid(i,imgs.length), config: { mass: 1, tension: 150, friction: 50 }, delay: i * 40 }))
      resizeGrid();
    } else if (contentLayout === 'random') {
      set((i) => ({
        ...random(i),
        config: { mass: 100, tension: 50, friction: 50 },
        delay: i * 40
      }))
    } else {
      set((i) => ({ ...spiral(i, imgs.length), config: { mass: 1, tension: 150, friction: 50 }, delay: i * 40 }))
    }
    //updateInstancedMeshMatrices({ mesh: meshRef.current, data });
   // playSound('boxUp')
  //  alert('contnetlayout')
  }, [contentLayout])

  useEffect(() => {
    console.log('filtergird', filterGrid);
   // playSound('more');
  }, [filterGrid])

  // useEffect(() => void setInterval(() => set(i => ({ ...random(i), delay: i * 40 })), 3000), [])
  /*
  return data.map((d, index) => {
    
    return (
      <a.mesh key={'grid'+index} {...springs[index]} >
        <planeBufferGeometry attach="geometry" args={d.args} />
        <a.meshStandardMaterial attach="material" color={springs[index].color} roughness={0.75} metalness={0.5} /> 
        <meshStandardMaterial attach="material" color="#fff" map={img}/>
      </a.mesh>
    )
  })
  */

 function loadContent(val, type){
  //alert(val)
  console.log(val)
  const n = val.lastIndexOf('/');
  const name = val.substring(n + 1);
  const path = (type === 'video' || val.includes('game')) ? type : 'fun';
 
  if (contentLayout==='grid') {

    if (type === 'game'){
      window.open(val, "_self");
    }else{
      if (val.includes('game')){
        navigate(val);
      }else{
        navigate('/'+path+'/'+name);
      }
  
    
      showFeature(val)
    }
  }
}



function checkSelection(val, action){

  if (contentLayout==='grid') {
   // console.log('selection '+val)
    document.body.style.cursor = action==='over' ? 'pointer' : 'none'

    setSelection(val);
    
    if (action==='out'){
/*
      clearTimeout(timer).then(function(){
            t = setTimeout(() => {
                console.log('wiping message');
                setSelection(val)
              }, 1000);
      });
 */
    }else{
    //  clearTimeout(timer);
     // setTimerState(false)
      
      //
    }
    
  }
}

  return data.map((d, index) => {
   // console.log(d)
   // console.log(d.args)
    return (
      
        <ImagePanel
          key={'grid'+index} 
          {...springs[index]}
          layout={contentLayout}
         // filter={filterGrid}
          type={(d.type === 'game') ? 'pdf' : d.type}
          url={d.thumb}
          link={d.content}
          args={d.args}
          currentColor={bkgdColor}
          onPointerOver={() => checkSelection(d.name, 'over')}
          onPointerOut={() => checkSelection('', 'out')}
          onClick={() => loadContent(d.content, d.type)}
          
        
        />
    )
  });
}

export default React.forwardRef(ContentGrid);