// navigator.mediaDevices.getUserMedia()
(() => {
  if(!navigator.mediaDevices) {
    navigator.mediaDevices = {}
  }
  
  if (!navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia = (
      navigator.mediaDevices.webkitGetUserMedia ||
      navigator.mediaDevices.mozGetUserMedia ||
      navigator.mediaDevices.msGetUserMedia ||
      navigator.getUserMedia ||
      (() => Promise.reject('getUserMedia is not supported in this browser'))
    )
  }
})()