// src/components/Overlay.js

import React, { useState, useEffect } from 'react';
import {
  useSceneStore,

} from '../store'
import GridItem from '../components/GridItem';
import CloseButton from '../components/CloseButton';



const BookActivitiesOverlay = () => {
 
  const { isCategoryOverlayVisible} = useSceneStore(state => state)
  const { setIsCategoryOverlayVisible} = useSceneStore(state => state.actions)

  
  useEffect(() => {
    setIsCategoryOverlayVisible(true);
      
  }, []);

  const toggleOverlay = () => {

    setIsCategoryOverlayVisible(false);
  };



  return (
    <div className={`overlay book-activities-overlay ${isCategoryOverlayVisible ? 'active' : ''}`}>
       <CloseButton
            toggleOverlay={toggleOverlay}
        />
         <div className="overlay-content">
          <div className='overlay-headline'>
            <h2>Choose a Book Activity</h2>
          </div>
          <div className="grid-content">


                <GridItem 
                  title="Be A Way Past Kid"
                  image="/assets/images/GreatBigFeelings_BeAWayPastKid.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/11/GreatBigFeelings_BeAWayPastKid_November23.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Way Past Mad Make-A-Face Activity"
                  image="/assets/images/WayPastMad_MakeAFace.jpg"
                  url="/assets/images/pdf/WayPastMad-faces.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Way Past Mad Dance with Jordyn & Hara"
                  image="/assets/images/dance-poster.jpg"
                  url="https://youtu.be/w2h3t-NqHto"
                 // target="_blank"
                  orient="horiz"
                  type="video"
                />

                <GridItem 
                  title="Way Past Mad Song Lyrics"
                  image="/assets/images/WayPastMad_SongLyrics.jpg"
                  url="/assets/images/pdf/WayPastMad-song.pdf"
                  target="_blank"
                />
                  <GridItem 
                  title="Way Past Worried Power Poses Coloring Sheet"
                  image="/assets/images/WayPastWorried_PowerPoses-coloring.jpg"
                  url="/assets/images/pdf/PowerPoses_coloring.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Way Past Jealous Draw Your Own Dogs Worksheet"
                  image="/assets/images/WayPastJealous_DrawYourOwnDogs-600.jpg"
                  url="/assets/images/pdf/Way_Past_Jealous-DrawYourOwnDogs_Worksheet-Updated_2023.pdf"
                  target="_blank"
                />


                <GridItem 
                  title="Way Past Jealous Star Shades Activity"
                  image="/assets/images/WayPastJealous_StarShades_Worksheet.jpg"
                  url="/assets/images/pdf/StarShades_Worksheet.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Way Past Jealous? Draw the Star You Are"
                  image="/assets/images/WayPastJealous_DrawTheStarYouAre.jpg"
                  url="/assets/images/pdf/JealousWorksheet_DrawTheStarYouAre.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="See Past Sad Telescope Activity"
                  image="/assets/images/WayPastSad_TelescopeActivity.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/WayPastSad_TelescopeActivity.pdf"
                  target="_blank"
                />



                <GridItem 
                  title="Way Past Sad? Draw Your Mood"
                  image="/assets/images/WayPastSad_DrawYourMood_Worksheet.jpg"
                  url="/assets/images/pdf/DrawYourMood_Worksheet.pdf"
                  target="_blank"
                />

              <GridItem 
                  title="Way Past Lonely A-MAZE-ING Me Activity"
                  image="/assets/images/WayPastLonely_A-MAZE-ing-thumbnail.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2024/03/WayPastLonely_A-MAZE-ingMe_Activity.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Way Past Lonely Power Crown Activity"
                  image="/assets/images/WayPastLonely_PowerCrownActivity-600.jpg"
                  url="/assets/images/pdf/PowerCrownActivity.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Way Past Afraid Thunder Dance Coloring Sheet"
                  image="/assets/images/WayPastAfraid_ThunderDanceColoringSheet.jpg"
                  url="/assets/images/pdf/ThunderDanceColoringSheet.pdf"
                  target="_blank"
                />



                <GridItem 
                  title="Way Past Sorry Bubbles Worksheet"
                  image="/assets/images/WayPastSorry_Bubbles_worksheet.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/WayPastSorry_Bubbles_worksheet.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Way Past Sorry? Make it Great!"
                  image="/assets/images/WayPastSorry_MakeItGreatworksheet_September2023.jpg"
                  url="/assets/images/pdf/WayPastSorry_MakeItGreatworksheet_September2023.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Way Past Embarrassed? Call for Help"
                  image="/assets/images/WayPastEmbarrassed_CallForHelpWorksheet.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/WayPastEmbarrassed_MakeACallWorksheet.pdf"
                  target="_blank"
                />

                <GridItem 
                  title="Way Past Mean Me Tee Activity"
                  image="/assets/images/WayPastMean_MyMeTeeWorksheet.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2024/05/WayPastMean_MyMeTeeWorksheet.pdf"
                  target="_blank"
                />

                <GridItem 
                  title="Way Past Mean Cone of Caring Activity"
                  image="/assets/images/WayPastMean_ConeOfCaringWorksheet.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2024/05/WayPastMean_ConeOfCaringWorksheet.pdf"
                  target="_blank"
                />


                <GridItem 
                  title="Way Past Mean Take A Shot Activity"
                  image="/assets/images/WayPastMean_BasketballActivitySheet.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/11/WayPastMean_BasketballActivitySheet.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Build a Quiet Ship Worksheet"
                  image="/assets/images/MyQuietShip_BuildAQuietShip-Checklist.jpg"
                  url="/assets/images/pdf/Quiet-Ship-Checklist.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Draw Your Own Quiet Ship"
                  image="/assets/images/MyQuietShip_DrawAQuietShip-600.jpg"
                  url="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/12/MyQuietShip_DrawYourOwnQuietShip_01.pdf"
                  target="_blank"
                 // orient="horiz"
                />
             
             
               
            
          </div>
        </div>
      </div>

  );
};

export default BookActivitiesOverlay;