import React, { useMemo, useState, useEffect } from "react";
import { animated, useTransition } from "react-spring";
import {
  useAudioStore,
  useSceneStore,
} from '../store'

const Message = ({ message='' }) => {
  const [msgArr, setMsgArr] = useState(message);
  const [headline, setHeadline] = useState(false);

 
  useEffect(() => {
    /*
    let msg;
    if (message.includes("<h1>")){
      msg = message.replace("<h1>", "").replace("</h1>", "");
      
      setHeadline(true)
    }else{
      msg = message;
    }
   // alert(msg)
   */
    setMsgArr(message)
  }, [message]);

  /*
  const items = useMemo(
    () =>  
      msgArr.split("").map((letter, index) => ({
        item: letter,
        key: index
      })),
    [msgArr]
    );

    
  const transitions = useTransition(items, item => item.key, {
    trail: 20,
   // from: { display: "none" },
   // enter: { display: "" }
    from: { opacity:0 },
    enter: { opacity:1 }
  });
  */


  return (
    <div className="DialogMessage">
      {msgArr}
      {/*
      {transitions.map(({ item, props, key }) => {
        return (
          <animated.span key={key} style={props}>
           {  item.item }
           
          </animated.span>
        );
      })}
    */}
    </div>
  );
};

export default Message;
