import React, { useRef, useEffect } from 'react'
//import { useGLTF } from '@react-three/drei'


import * as THREE from 'three'

import { useLoader } from '@react-three/fiber'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { TextureLoader } from 'three/src/loaders/TextureLoader.js'

import { useTexture } from "@react-three/drei"


export default function Book({ url, ...props}) {
  const group = useRef()
  //const { nodes, materials } = useGLTF('/book-sad3.glb')
  const { nodes, materials } = useLoader(GLTFLoader, '/book-sad3.glb')
  const tex1 = useLoader(TextureLoader, '/'+url);
/*
  //const tex1 = useTexture(url);
  const texture = useTexture(url, (texture) => {
    texture.flipY =false;
    texture.encoding = THREE.sRGBEncoding;
  })
  */
  
  tex1.flipY =false;
  tex1.encoding = THREE.sRGBEncoding;

  useEffect(() => {

    //console.log('book-render:', url)
},[])


  return (
    <group ref={group} {...props} dispose={null} rotation={[0,Math.PI / 2, 0]} scale={[2,2,2]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.cgaxis_models_53_06003.geometry}
        material={materials.cgaxis_models_53_06_02}
        position={[0.48, 0, -0.46]}
        rotation={[-Math.PI, 0, 0]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.cgaxis_models_53_06004.geometry}
       // material={nodes.cgaxis_models_53_06004.material}
        position={[0.48, 0, -0.46]}
        rotation={[-Math.PI, 0, 0]}
      >
        <meshStandardMaterial 
          attach="material" 
          map={tex1}
          flatShading
          toneMapping={false}
         />
     </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.cgaxis_models_53_06018.geometry}
        material={nodes.cgaxis_models_53_06018.material}
        position={[0.48, 0, -0.46]}
        rotation={[-Math.PI, 0, 0]}
      />
    </group>
  )
}

//useGLTF.preload('/book-sad3.glb')
