import React, { useEffect, useState, useRef } from 'react'
//import { render } from 'react-dom'
import { animated, useTransition, useSpring,useSpringRef, useChain, config } from '@react-spring/web'
import { shadeColor } from "../utils"

import {
  useSceneStore,

} from '../store'

//import data from './data'
//import logoW from './W.png'
//import logoP from './logo-p.png'
//import logoB from './logo-b.png'

// Gradients taken from: https://webgradients.com/
const data =[ {
      name: 'Rare Wind',
      description: '#a8edea → #fed6e3',
      // css: 'linear-gradient(135deg, #a8edea 0%, #fed6e3 100%)',
      css:
        'transparent url("/assets/images/W.png") no-repeat center / contain',
      height: 400,
      width: 640,
      offset: 0
    },
    {
      name: 'Saint Petersburg',
      description: '#f5f7fa → #c3cfe2',
      // css: 'linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)',
      css:
        'transparent url("/assets/images/logo-p.png") no-repeat center / contain',
      height: 400,
      width: 400,
      offset: -20
    },
    {
      name: 'Deep Blue',
      description: '#e0c3fc → #8ec5fc',
      // css: 'linear-gradient(135deg, #e0c3fc 0%, #8ec5fc 100%)',
      css:
        'transparent url("/assets/images/logo-b.png") no-repeat center / contain',
      height: 400,
      width: 400,
      offset: 0
    }
    
  ]
  

export default function LogoBug({ currentColor}) {
 // const [open, set] = useState(showBug)
  const [open, set] = useState(true)
  const [bugColor, setBugColor] = useState(currentColor)
  const { showBug} = useSceneStore(state => state)

  const springRef = useSpringRef()
  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config.wobbly,
    from: { size: '0px', background: bugColor },
    to: { size: showBug ? '56px' : '0px', background: shadeColor(bugColor,-20) }
  })

  const transRef = useSpringRef()
  const transitions = useTransition(showBug ? data : [], {
    ref: transRef,
    unique: true,
    trail: 200 / data.length,
    config: config.wobbly,
    from: { opacity: 0, transform: 'translateY(50px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-10px)' }
  })

  /*
  const transitions = useTransition(open ? data : [], (item) => item.name, {
    ref: transRef,
    unique: true,
    trail: 200 / data.length,
    config: config.wobbly,
    from: { opacity: 0, transform: 'translateY(50px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-10px)' }
  })
  */

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(showBug ? [springRef, transRef] : [transRef, springRef], [0, showBug ? 0.1 : 0.2])
/*
  useEffect(() => {
    set(showBug)
  }, [showBug])
  */

  useEffect(() => {
    setBugColor(currentColor)
  }, [currentColor])

  return (
    <div className="logoBox" onClick={() => set((open) => !open)}>
      <div className="logoHolder">
        <animated.div className="logoCircle" style={{ ...rest, width: size, height: size }} />
        {transitions(( props , item, key) => {

         return(
          <animated.div className="letter" style={{ ...props, width: item.width, marginTop: item.offset, background: item.css }} />
         )
        })}

      
       {/* transitions.map(({ item, key, props }) => (
          <animated.div className="letter" key={key} style={{ ...props, width: item.width, marginTop: item.offset, background: item.css }} />

       )) */}
      </div>
    </div>
  )
}