import * as THREE from 'three'
import React, { useRef,useEffect, useState } from 'react'
import { useSprings, a } from '@react-spring/three'
import {  useThree, useFrame } from '@react-three/fiber'


import Book from '../Book'
import {
    useSceneStore,
  } from '../store'

  import useStore from '../store/scroll'

//import './styles.css'
const rfs = THREE.MathUtils.randFloatSpread
const number = 2
//const colors = ['#A2CCB6', '#FCEEB5', '#EE786E', '#e0feff', 'lightpink', 'lightblue']

const winHeight = window.innerHeight/2;
const halfHeight = winHeight/4;

const random = (i) => {
  const mobile = window.innerWidth < 700;
  const r = Math.random()
  const range = 30;
  //r * range - range / 2

  return {
   // position: [(i*10) - Math.random() * 50, 0 , i * 40 - 30],
    position: [
      (mobile) ? (i*9) -25 :(i*18) -50, 
      (mobile) ?  (Math.random()*40)-20   : (Math.random()*20)-10 , 
      (mobile) ? i * 20 - 15 : i * 40 - 30
    ],
    // color: colors[Math.round(Math.random() * (colors.length - 1))],
    scale: [1, 1, 1],
    opacity: 1,
    rotation: [0, 0, THREE.Math.degToRad(Math.round(r * range - range / 2) )]
  }
}

const data = new Array(number).fill().map((i) => {
  return {
   // color: colors[i],
    args: [30, 40, 2]
  }
})

function ContentOther({ movement, cycleBooks, introCallback }) {


  const imgArr =[
    'strongest-thing-cover-wrap-award.jpg',
    'quietship-cover-wrap.jpg',
    

  ]
  imgArr.reverse();


  return data.map((d, index) => {

    return(
      <a.group key={index} 
      {...movement[index]} 
      onClick={cycleBooks}
      >
        <Book rotation={[Math.PI / 2, 0, 0]} url={"assets/images/"+imgArr[index]}/>
      </a.group>
    )

    })
}


export default function BookCarouselOther({ ...props}) {
  //const [bookIndex, setBookIndex] = useState(3)
  //const [triggerState, setTriggerState] = useState(trigger);
  const { setBookOtherIndex, setBookOtherState} = useSceneStore(state => state.actions)
  const { bookOtherState, bookOtherIndex } = useSceneStore(state => state)

  const sectionActive = useStore(state => state.sectionActive)

  const { viewport } = useThree()

  const [springs, set] = useSprings(number, (i) => ({
    from: {
      position: [i,-4 *viewport.height,-10],
      // color: colors[Math.round(Math.random() * (colors.length - 1))],
    //  scale: [1.5, 1.5, 1.5],
      opacity: 1,
      rotation: [THREE.Math.degToRad(Math.round(Math.random()) * -80), THREE.Math.degToRad(Math.round(Math.random()) * 30), THREE.Math.degToRad(Math.round(Math.random()) * 45)]
    },
    to: {
      position: [(i*15), 0 ,-10],
      // color: colors[Math.round(Math.random() * (colors.length - 1))],
    //  scale: [1, 1, 1],
      opacity: 1,
      rotation: [THREE.Math.degToRad(Math.round(Math.random()) * 30), THREE.Math.degToRad(Math.round(Math.random()) * 10), THREE.Math.degToRad(Math.round(Math.random()-.3) * 85)]
    },
    config: { mass: 100, tension: 10, friction: 20 },
    onStart: () => {

     // introCallback();
      //  console.log('did it')
    }
  }))
/*
  const [springs, set] = useSprings(number, (i) => ({
    from: {
      position: [(i*20) - Math.random() * 60, -sectionActive * viewport.height, i * 80],
      // color: colors[Math.round(Math.random() * (colors.length - 1))],
      scale: [1.5, 1.5, 1.5],
      opacity: 1,
      rotation: [THREE.Math.degToRad(Math.round(Math.random()) * -80), THREE.Math.degToRad(Math.round(Math.random()) * 30), THREE.Math.degToRad(Math.round(Math.random()) * 45)]
    },
    to: {
      position: [(i*15) - Math.random() * 50, -sectionActive * viewport.height , i * 50],
      // color: colors[Math.round(Math.random() * (colors.length - 1))],
      scale: [1, 1, 1],
      opacity: 1,
      rotation: [THREE.Math.degToRad(Math.round(Math.random()) * 30), THREE.Math.degToRad(Math.round(Math.random()) * 10), THREE.Math.degToRad(Math.round(Math.random()-.3) * 85)]
    },
    config: { mass: 100, tension: 10, friction: 20 },
    onStart: () => {

     // introCallback();
      //  console.log('did it')
    }
  }))
*/
  const [count, setCount] = useState(0);
  // 👇 look here
  const prevCount = usePrevious(count)

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  useEffect(() => {

  }, []);

  useEffect(() => {
   // console.log(bookIndex)
    stepBookCarousel(count)
    setCount(bookOtherIndex)
    
    
  }, [bookOtherIndex])

  
/*
  useEffect(() => {
    //introCallback();
     // setTriggerState(trigger)
    
      if (bookOtherState === 'float'){
        relaunch();
      }else if (bookOtherState === 'stack'){
        assemble();
      }else if (bookOtherState === 'lineup'){
        alignAll();
      }else if (bookOtherState === 'scatter'){
        scatterAll();
      }
   


    
  }, [bookOtherState])
  */

  useEffect(() => {

   if (sectionActive === 5){
     // assemble();
      stepBookCarousel(2)
    }else{
    // scatterAll();
    }
    
  }, [sectionActive]);

  function relaunch() {
    console.log('relaunch')

    set((i) => ({ ...random(i), config: { mass: 20, tension: 10, friction: 20 }, delay: i * 40 }))
    
  }
  function assemble() {
    console.log('OTHER assemble')
   // setBookOtherIndex(5)
    setBookOtherIndex(1)
    const mobile = window.innerWidth < 700;
    const xoffset = (mobile)? 15 : 40;
    const zoffset = (mobile)? 60 : 150;
    set((i) => ({
      ...{
       // position: [i * 6- xoffset, 0, i * 17 + zoffset],
        position: [
          (mobile) ? i*3- 10 : i*20 + xoffset , 
          -sectionActive * viewport.height - 15,  
           zoffset  
        // 20// (mobile) ? i * 10 + 80   : i * 17 + zoffset  
        ],
        // color: colors[Math.round(Math.random() * (colors.length - 1))],
        opacity: 1,
        scale: [1, 1, 1],
        rotation: [0, THREE.Math.degToRad(45), THREE.Math.degToRad(0)]
      },
      config: { mass: 20, tension: 200, friction: 97 },
      delay: i * 40
    }))
  }

  function alignAll() {
    console.log('align')
    set((i) => ({
      ...{
        position: [i * 40 - 60, 0, -200],
        opacity: 1,
        // color: colors[Math.round(Math.random() * (colors.length - 1))],
        scale: [1, 1, 1],
        rotation: [0, THREE.Math.degToRad(0), THREE.Math.degToRad(0)]
      },
      config: { mass: 20, tension: 200, friction: 97 },
      delay: i * 40
    }))
  }

  function scatterAll() {
    console.log('scatter')
   /// setBookOtherState('scatter')
    set((i) => ({
      ...{
      //  position: [i * 40 - rfs(500), rfs(500), -200 - rfs(1000)],
        position: [i * 40 + 500 + rfs(500),  -sectionActive * viewport.height + rfs(100) -200, -200 - rfs(100)],
        opacity: 1,
        // color: colors[Math.round(Math.random() * (colors.length - 1))],
        scale: [1, 1, 1],
        rotation: [0, THREE.Math.degToRad(0), THREE.Math.degToRad(0)]
      },
      config: { mass: 20, tension: 200, friction: 97, precision: 0.0001 },
      delay: i * 40
    }))
  }

  function cycleBooks(){
    if (bookOtherState === 'stack'){
      console.log('cyclebooks',count)
      if (count >1) {
      // stepBookCarousel(count-1,0)
        setBookOtherIndex(count-1)
      }else{
      //  stepBookCarousel(3, 1)
        setBookOtherIndex(number)
      }
    }
  }



  function stepBookCarousel(idx, reverse = 0) {

    //console.log('Step Other Carousel', idx)

    const mobile = window.innerWidth < 700;
   // const xoffset = (mobile)? 15 : 40;
   // const zoffset = (mobile)? 80 : 140;

    const xoffset = (mobile)? 15 : 42;
    const zoffset = (mobile)? 60 : 120;

  //  console.log('idx----', idx)

    set((i) =>
      i >= idx
        ? {
            //  if (i===idx) {
            ...{
             // position: reverse === 0 ? [i * 10, 0, i * 20 + 200] : [i * 10 - 60, 0, i * 20 + 80],
             // position: [40, 0, 500],
              position: [
                40, 
              //  -sectionActive * viewport.height, 
                (mobile) ? -sectionActive * viewport.height + viewport.height/2   : -sectionActive * viewport.height ,  
                500],
              // color: colors[Math.round(Math.random() * (colors.length - 1))],
              scale: [1, 1, 1],
            //  opacity: i === idx ? reverse : 1,
              rotation: [0, THREE.Math.degToRad(-30), THREE.Math.degToRad(0)]
            },
            config: { mass: 1, tension: 300, friction: 97 }
            // delay: i * 40

            // }
          }
        : {
          //  if (i===idx) {
           
          ...{
           // position: reverse === 0 ? [i * 10, 0, i * 20 + 200] : [i * 10 - 60, 0, i * 20 + 80],
      //      position: [i * 8 - xoffset, 0, i * 15 + zoffset],

       //     position: [i * 6- xoffset, 0, i * 17 + zoffset],
    //   position: [i*6- xoffset + 36 - (idx*6) - (6-idx) , 0,  i * 17 + zoffset + 72 - (idx*12)  ],
  //  position: [i*3- 10 + 18 - (idx*3) - (6-idx) , 0,  i * 8.5 + zoffset + 36 - (idx*6)  ],
   // position: [i*3- 10  , 0,  i * 10 + 80  ],
    position: [
     // (mobile) ? i*3- 10 + 18 - (idx*3) - (6-idx) : i*6+ xoffset + 36 - (idx*6) - (6-idx), 
      (mobile) ? i*3- 10 + 18 - (idx*3) - (6-idx) : i*6 + viewport.width/8 - 10 , 
      (mobile) ? -sectionActive * viewport.height + viewport.height/2 - 30  : -sectionActive * viewport.height - 14,  
      (mobile) ? i * 10 + 80 + 72 - (idx*12)  : i * 17 + zoffset + 72 - (idx*12) 
    ],
        //    position: [i * 6 - xoffset + (24/bookIndex )-4 , 0, i * 17 + zoffset + (6*10/(bookIndex+1)) ],
            // color: colors[Math.round(Math.random() * (colors.length - 1))],
            scale: [1, 1, 1],
          //  opacity: i === idx ? reverse : 1,
            rotation: [0, THREE.Math.degToRad(-30), THREE.Math.degToRad(0)]
          },
          config: { mass: 20, tension: 200, friction: 100, precision: 0.0001 }
          // delay: i * 40

          // }
        }
    )
  }

  return (
    <>
         {/*   <axesHelper scale={80} /> */}

            <ContentOther
              movement={springs} 
              cycleBooks={cycleBooks}
            // introCallback={introCallback}
              />
  
    </>
  )
}