// src/components/Overlay.js

import React, { useState } from 'react';
import {
  useSceneStore,

} from '../store'
import CloseButton from '../components/CloseButton';



const Overlay = () => {
 
  const { isOverlayVisible} = useSceneStore(state => state)
  const { setIsOverlayVisible} = useSceneStore(state => state.actions)
 

  const toggleOverlay = () => {
    setIsOverlayVisible(false);
  };

  return (
    <div className={`overlay ${isOverlayVisible ? 'active' : ''}`}>
        
         <CloseButton
            toggleOverlay={toggleOverlay}
            //goBack={false}
        />
         <div className="overlay-content">
          <div className='overlay-headline'>
            <h2>Choose a Lesson Plan</h2>
          </div>
          <div className="grid-content">

       

        <a href="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/WayPastSorryLessonPlan.pdf" target="_blank" >
         
          <img
            src="/assets/images/lessonplans/WayPastSorryLessonPlan.jpg"
            alt="Way Past Sorry Lesson Plan"
          />
        </a>

        <a href="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/WayPastEmbarrassed_LessonPlan.pdf" target="_blank" >
         
          <img
            src="/assets/images/lessonplans/WayPastEmbarrassed_LessonPlan.jpg"
            alt="Way Past Embarrassed Lesson Plan"
          />
        </a>

         <a href="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2021/10/Way_Past_Lonely_Lesson_Plan.pdf" target="_blank" >
      
          <img
            src="/assets/images/lessonplans/Way_Past_Lonely_Lesson_Plan.jpg"
            alt="Way Past Lonely Lesson Plan"
          />
        </a>
        <a href="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/02/WayPastAfraid_LessonPlan_Feb2023.pdf" target="_blank" >
        
          <img
            src="/assets/images/lessonplans/WayPastAfraid_LessonPlan_Feb2023.jpg"
            alt="Way Past Afraid Lesson Plan"
          />
        </a>
        <a href="/assets/images/Way-Past-Jealous-Lesson-Plan_COLOR.pdf" target="_blank" >
    
          <img
            src="/assets/images/lessonplans/Way-Past-Jealous-Lesson-Plan_COLOR.jpg"
            alt="Way Past Jealous Lesson Plan"
          />
        </a>
        <a href="/assets/images/Way-Past-Sad-Lesson-Plan_COLOR.pdf" target="_blank" >
        
          <img
            src="/assets/images/lessonplans/Way-Past-Sad-Lesson-Plan_COLOR.jpg"
            alt="Way Past Sad Lesson Plan"
          />
        </a>


        <a href="/assets/images/Way-Past-Worried-Lesson-Plan_COLOR.pdf" target="_blank" >
      
        <img
            src="/assets/images/lessonplans/Way-Past-Worried-Lesson-Plan_COLOR.jpg"
            alt="Way Past Worried Lesson Plan"
          />
        </a>

        <a href="/assets/images/Way-Past-Mad-Lesson-Plan_COLOR.pdf" target="_blank" >
      
        <img
            src="/assets/images/lessonplans/Way-Past-Mad-Lesson-Plan_COLOR.jpg"
            alt="Way Past Mad Lesson Plan"
          />
        </a>

        <a href="https://halleeadelman.wohpro.com/wp-content/uploads/sites/3/2023/10/TheStrongestThing_LessonPlan.pdf" target="_blank" >
         
         <img
           src="/assets/images/lessonplans/TheStrongestThing_LessonPlan.jpg"
           alt="The Strongest Thing Lesson Plan"
         />
       </a>

        <a href="https://myquietship.com/static/assets/HalleeAdelman-LessonPlan-QuietShip.pdf" target="_blank" >
      
      <img
          src="/assets/images/lessonplans/MyQuietShip-lesson-plan-color-thumb.jpg"
          alt="My Quiet Ship Lesson Plan"
        />
      </a>
        </div>
        </div>
      </div>

  );
};

export default Overlay;