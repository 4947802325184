import React, { useEffect,useLayoutEffect, useState, useRef } from "react";
//import { Link, Element, Events, animateScroll, scrollSpy, scroller } from 'react-scroll'
import { useMediaQuery } from 'react-responsive'
import { useFrame, useThree } from '@react-three/fiber'
import {ScrollControls, Scroll, useScroll, Html } from '@react-three/drei'
import { mediaQueries } from '../config'
import Lottie from 'react-lottie';
import animationData from '../lotties/wpb-logo';
import InViewMonitor from 'react-inview-monitor';
import { useRoutes, A, navigate} from "hookrouter";
import ActivityPanel from "../components/ActivityPanel";
import ActivityPanel2 from "../components/ActivityPanel2";

import YouTube from 'react-youtube';
import Slides from "../components/Slides";
import SlidesOther from "../components/SlidesOther";
import Carousel from "./Carousel";
import logo from '../assets/waypastlogo.png';
import useSound from 'use-sound';
import audioMap from '../resources/audio';
import { useLocation } from "react-router-dom";

import {
  useAudioStore,
  useSceneStore,

} from '../store'

import useStore from '../store/scroll'
/*
import {
  useLocation,
} from "react-router-dom";
*/
import {usePath} from 'hookrouter';
import Iframe from "react-iframe";
import InstaGallery from "./InstaGallery";

let defaultHeight;

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};



const mobile = window.innerWidth < 700;
  const mobileSectionHeight = 70;


  const opts = {
    height: (mobile) ? '234' :'480',
    width: (mobile) ? '416' :'854',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
    //  autoplay: 1,
    },
  };

export default function ScrollWindow(){

  const { pageState, cursorActive, isOverlayVisible, youTubeArr, featured, modalState, domRef, scrollRef, events, mode, pages, top, scrollPos, zoom, panel, sections, bookIndex, bookOtherIndex, contentLayout, bkgdColor, loaded, bookState, showBug, shuffled, character, topic, talk} = useSceneStore(state => state)
  const { setPageState, setIsOverlayVisible, setCursorActive, setFilterGrid, setFullMenuVisible, setFeatured, setModalState, setDomRef, setEvents,setMode,setScene, setScrollPos, setBookState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setBookOtherIndex,setLoaded } = useSceneStore(state => state.actions)
 
  const updateScrollPos = useStore(store => store.updateScrollPos);
  const sectionActive = useStore(state => state.sectionActive)
  const scrollPosition = useStore(state => state.scrollPos)

  const { setWasWelcomed, setWasWelcomedToFun, setWasWelcomedToVideo} = useAudioStore(state => state.actions)


  


  const [active, setActive] = useState(false)
  const [showIntroPanel, setShowIntroPanel] = useState(true)


 // const { gridHeight, messages, youTubeArr, domRef, scrollRef, events, mode, pages, top, scrollPos, zoom, panel, sections, bookIndex, contentLayout, bkgdColor, loaded, bookState, showBug, shuffled, character, topic, talk} = useSceneStore(state => state)
 // const { setFullMenuVisible, setFilterGrid, setDomRef,setScrollRef, setMode,setScene, setScrollPos, setBookState, setBookOtherState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setLoaded, setDefaultHeight } = useSceneStore(state => state.actions)
  
 // const isMobile = useMediaQuery({ query: mediaQueries.mobile })
  const isTablet = useMediaQuery({ query: mediaQueries.tablet })
  const isDesktop = useMediaQuery({ query: mediaQueries.desktop })

//  const { playSound, setWasWelcomed, setWasWelcomedToVideo, setWasWelcomedToFun } = useAudioStore(state => state.actions)
  const wasWelcomed = useAudioStore(state => state.wasWelcomed)
  const wasWelcomedToVideo = useAudioStore(state => state.wasWelcomedToVideo)
  
  const wasWelcomedToFun = useAudioStore(state => state.wasWelcomedToFun)

  const { setCtxSuspended } = useAudioStore(state => state.actions)
 
  //const startWelcome = useAudioStore(state => state.startWelcome)
  //const stopWelcome = useAudioStore(state => state.stopWelcome)

  const scrollArea = useRef()
  const domContent = useRef()
  const gridSpacerRef = useRef()
  const spacerRef = useRef()
  const location = usePath();//useLocation();
  //const mobile = window.innerWidth < 700;

 

  const scroll = useScroll()

  const [playWelcome, endWelcome] = useSound(audioMap.welcome, {
    volume: 1,
    interrupt: true,
  });
  const [playMore, endMore] = useSound(audioMap.more, {
    volume: 1,
    interrupt: true,
  });
  const [playWayPastHappy, endWayPastHappy] = useSound(audioMap.waypasthappy, {
    volume: 1,
    interrupt: true,
  });
  const [playPlay, endPlay] = useSound(audioMap.play, {
    volume: 1,
    interrupt: true,
  });
  const [playPastFun, endPastFun] = useSound(audioMap.pastfun, {
    volume: 1,
    interrupt: true,
  });


  

  function setCursor(msg, boo){
    setCursorActive(boo)
    setTopic(msg)
  }

  function isMobile(){
    return (mobile)? false : true;
  }

  function welcomeMessage(){
    if (!wasWelcomed){
      //alert('welcome')
      console.log('welcome')
      playSound('welcome');
      setWasWelcomed(true)
      document.removeEventListener("click", welcomeMessage);
    }else{
     // alert('hi again')
      document.removeEventListener("click", welcomeMessage);
    }
  }


  useEffect(() => {

  //  document.addEventListener("click", welcomeMessage);

 
  //  playSound('welcome')
   // resize()
   // window.addEventListener("resize", resize)
   // return () => {
   //   window.removeEventListener("resize", resize)
   // }
    
    
  }, []);


/*
  useEffect(() => {
      console.log('gridHeight is '+gridHeight+'px')
     
   }, [gridHeight]);
*/

   function killAllSounds(){
    endPastFun.stop();
    endMore.stop();
   // endWelcome.stop();
   // stopWelcome();
    endWayPastHappy.stop();
    endPlay.stop();

   }

  function playSound(key){
    killAllSounds();
    setCtxSuspended(false)
/*
    if (!wasWelcomed){
      playSound('welcome');
      setWasWelcomed(true);
    }else{

      if(key === 'more'){
        playMore(); 

      }else if(key === 'waypasthappy'){
        playWayPastHappy();
      }else if(key === 'play'){
        playPlay();
        setWasWelcomedToFun(true);
      }else if(key === 'pastfun'){
        playPastFun();
        setWasWelcomedToVideo(true);
      }
    }
  */

 
    if(key === 'welcome'){
      playWelcome();
      //startWelcome();
    }else if(key === 'more'){
      playMore(); 
    }else if(key === 'waypasthappy'){
      playWayPastHappy();
    }else if(key === 'play'){
      playPlay();
    }else if(key === 'pastfun'){
      playPastFun();
    }
    
   }

  function scrollToSection(num){

    const sectHt  = 1 / 6
    const scrollHt  = 1 / 6
   // const sect = Math.floor(scroll.offset / sectHt);
    const newPercent = num * sectHt; // + (sectHt/2);

  //  scroll.scroll.current= newPercent;
    scroll.el.scrollTop = scroll.el.scrollHeight * newPercent;
    ///???????

  }



  function navToHome(){
   // alert('navtohome')
      setMode('scroll')
      setContentLayout('spiral');
      setBookState('float');

      setFilterGrid(null)

    // animateScroll.scrollToBottom();

     console.log('scroll', scroll)
     console.log('scroll.el', scroll.el)
     console.log('scroll.el.scrollHeight', scroll.el.scrollHeight)

     scroll.scroll.current=0;
     scroll.el.scrollTop = 0;
   
      /*
      animateScroll.scrollTo(0,{

          smooth: true,
          containerId: 'scroller',
         // offset: 50, // Scrolls to element + 50 pixels down the page
        
        });
        */
  }

  function navToVideos(){
    setMode('grid')
    setFilterGrid('video')
    setContentLayout('grid')

   

  //  console.log('location',location)

 //   scroll.scroll.current=0;
 //    scroll.el.scrollTop = 0;
  //  scrollArea.current.scrollTop = 0;

  }

  function navToFun(){
    setMode('grid')
    setFilterGrid('pdf')
    setContentLayout('grid')
   //  console.log('location',location)
   // scroll.scroll.current=0;
   // scroll.el.scrollTop = 0;

  }

  function navToBooks(){
         console.log('BOOKS ------')
        setMode('scroll')
        setContentLayout('spiral');
        //scrollArea.current.scrollTop = 2000;
     //   console.log('defaultHeight', defaultHeight)
     //   console.log('scrollArea.current.scrollHeight', scrollArea.current.scrollHeight)
        
        const decimal = isMobile ? .65 : .675;

      //  scrollToSection(4)

       // scroll.scroll.current=0;
      //  scroll.el.scrollTop = 0;

        const sectHt  = 1 / 7
      // const scrollHt  = 1 / 6
       // const sect = Math.floor(scroll.offset / sectHt);
        const newPercent = 4 * sectHt; // + (sectHt/2);
        console.log('newPercent', newPercent)

        console.log('book-scroll', scroll.el.scrollHeight)
        const curHeight = scroll.el.scrollHeight
    
        scroll.scroll.current= newPercent;
        scroll.el.scrollTop = curHeight * (newPercent +.01);
        

      //  animateScroll.scrollToBottom();
/*
        animateScroll.scrollTo(defaultHeight*decimal,{

          delay: 100,
          smooth: true,
          containerId: 'scroller',
        // offset: 50, // Scrolls to element + 50 pixels down the page
        
        });
        */
  }

  function navToMore(){
   
    setMode('scroll')
    setContentLayout('spiral')

    //scrollToSection(6)

    if (isMobile){
      console.log('mobile more')
      scroll.scroll.current= 0.95;
      scroll.el.scrollTop = scroll.el.scrollHeight *0.95;

    }else{

    scroll.scroll.current= 1;
    scroll.el.scrollTop = scroll.el.scrollHeight;

    }
    /*
    animateScroll.scrollTo(defaultHeight*.9,{
      smooth: true,
      containerId: 'scroller',
      // offset: 50, // Scrolls to element + 50 pixels down the page
    
    });
    */
  }

 

  useEffect(() => {
    //console.log(scroll)
    //alert(location.pathname)
    let timer;

   // const doesAnyHistoryEntryExist = location.key !== "default";
   

   
    const currentPath = location;//.pathname;

    if (
      pageState !== '/fun-and-games' &&
      pageState !== '/videos' &&
      !pageState.includes('/fun') &&
      !pageState.includes('/video') &&
      !pageState.includes('/category')
    ){
      scroll.scroll.current=0;
      scroll.el.scrollTop = 0;
    }

    if (isMobile){
      timer = setTimeout(() => {
        setFullMenuVisible(false);
      }, 200);
    }
    
    console.log(currentPath)
    if (currentPath === '/'){
      navToHome();
      if (!wasWelcomed){
      //  playSound('welcome');
       // setWasWelcomed(true);
      }
      console.log('/ home')
    }else if (currentPath === '/videos' || currentPath.includes('/video')){
      console.log('videos path')


      
     
     // console.log('history', history)
      navToVideos();
  
      if (!wasWelcomed){
        playSound('welcome');
        setWasWelcomed(true)
      }else if (!wasWelcomedToVideo){

        playSound('pastfun');
        setWasWelcomedToVideo(true);
      }
/*
   
      if (currentPath === '/videos' && !wasWelcomedToVideo){

        if (!wasWelcomed){
            playSound('welcome');
            setWasWelcomed(true)
        }else{

          playSound('pastfun');
        }

        playSound('pastfun');
        setWasWelcomedToVideo(true);
  
      }   */  
    }else if (currentPath === '/fun-and-games' || currentPath.includes('/fun') || currentPath.includes('/category')){
      
   //   console.log('history', history)
      navToFun();
      if (!wasWelcomed){
        playSound('welcome');
        setWasWelcomed(true)
      }else if (!wasWelcomedToFun){

        playSound('play');
        setWasWelcomedToFun(true);
      }
   //   playSound('play');
      /*
      if (currentPath === '/fun-and-games' && !wasWelcomedToFun) {

        if (!wasWelcomed){
          playSound('welcome');
          setWasWelcomed(true)
      }else{
        playSound('play');
      }
      // 

        playSound('play');
        setWasWelcomedToFun(true);
       
      } */
    }else if (currentPath === '/books'){
     

     

      timer = setTimeout(() => {
        navToBooks();
      }, 300);
     

      if (!wasWelcomed){
        playSound('welcome');
        setWasWelcomed(true)
      }else{
        playSound('waypasthappy');
      }
    }else if (currentPath === '/more'){
     // navToMore();
      timer = setTimeout(() => {
        navToMore();
      }, 300);

      if (!wasWelcomed){
        playSound('welcome');
        setWasWelcomed(true)
      }else{
        playSound('more');
      }
      
 //     playSound('more');

}else if (currentPath === '/lesson-plans'){
  setIsOverlayVisible(true)
   
//     playSound('more');

 
    }else{
      navToHome();
      if (!wasWelcomed){
        //  playSound('welcome');
         // setWasWelcomed(true);
      }
      console.log('default home')
    }


    console.log("location", location);
    setPageState(location)
   

    return () => clearTimeout(timer);
  }, [location]);


  const onScroll = (e) => {

   // top.current = e.target.scrollTop;
   // const percent = Math.ceil((e.target.scrollTop/(e.target.scrollHeight-window.innerHeight))*100);
  //  setScrollPos(percent)

  
/*
    const inc = ((isMobile) ? 70:100)/sections;
    //console.log('inc',inc)
    if (percent > inc*6.25){
      setBookOtherState('scatter')
      if (isMobile){
        setBookState('stack')
        setHomeMessage('')
      }else{
        setBookState('lineup')
        setHomeMessage(messages[4])
      }
    }else if (percent > inc*5.5){
        if (!isMobile){
          setBookState('scatter')
          setBookOtherState('stack')
          setHomeMessage('')
        }
      
    }else if (percent > inc*4.5){
      setBookOtherState('scatter')
      if (!isMobile){
        setBookState('stack')
        setHomeMessage('')
      }
    }else if (percent > inc*4){
      setBookState('float')
     // setCharacter(3)
    }else if (percent > inc*3){
      setBkgdColor(3)
      setCharacter(3)
      setTalk(3)
      //setHomeMessage(3)
      //setHomeMessage(youTubeArr[1][1])
      setHomeMessage('Book Song Challenge 2021 Highlights')
     
      
    
    }else if (percent > inc*2){
      setBkgdColor(2)
      setCharacter(2)
      setTalk(2)
      setShuffled(true)
      setHomeMessage(messages[2])

    }else if (percent > inc*1){
     
      setShuffled(false)
    }else if (percent > inc*.5){
      setBkgdColor(1)
      setCharacter(1)
      setShowBug(true)
      setBookState('float')
      //setHomeMessage(youTubeArr[0][1])
      //setHomeMessage('Book Song Challenge 2021 Highlights')
      setHomeMessage('Join Us on May 7!')
    //  console.log(youTubeArr[0][1])
      setTalk(1)
    }else{
      setBkgdColor(0)
      setCharacter(0)
      setHomeMessage(messages[0])
      setShowBug(false)
      
      //setBookState('float')
    }

    */

  }
  
  //useEffect(() => void onScroll({ target: scrollArea.current }), [])


  useFrame(() => {
    // data.offset = current scroll position, between 0 and 1, dampened
   // console.log('scroll :', scroll.offset)
    updateScrollPos(scroll.offset.toFixed(2))

    if(scroll.offset.toFixed(2)> 0){
     // alert('scroll')
      setCtxSuspended(false)
    }

    //const sect = (sectionActive < 0 ) ? 0 : sectionActive+1;

    const sectHt  = 1 / sections
    const sect = Math.floor(scroll.offset / sectHt);
   // console.log('sect', sect)

   // setBkgdColor(sect)
   // setCharacter(sect)
   
/*
    if (sectionActive < 0){
      setBkgdColor(0)
      setCharacter(0)
      //setHomeMessage(messages[0])
      setShowBug(false)
   } else if (sectionActive === 0){
      setBkgdColor(1)
      setCharacter(1)
      setShowBug(true)
     // setBookState('float')
      //setHomeMessage(youTubeArr[0][1])
      //setHomeMessage('Book Song Challenge 2021 Highlights')
      setHomeMessage('Join Us on May 7!')
    } else if (sectionActive === 1){
      setBkgdColor(2)
      setCharacter(2)
      setShowBug(true)
     // setBookState('float')
      //setHomeMessage(youTubeArr[0][1])
      //setHomeMessage('Book Song Challenge 2021 Highlights')
     // setHomeMessage('Join Us on May 7!')
   }
    */
    // data.delta = current delta, between 0 and 1, dampened
/*
    // Will be 0 when the scrollbar is at the starting position,
    // then increase to 1 until 1 / 3 of the scroll distance is reached
    const a = data.range(0, 1 / 3)
    // Will start increasing when 1 / 3 of the scroll distance is reached,
    // and reach 1 when it reaches 2 / 3rds.
    const b = data.range(1 / 3, 1 / 3)
        // Same as above but with a margin of 0.1 on both ends
    const c = data.range(1 / 3, 1 / 3, 0.1)
    // Will move between 0-1-0 for the selected range
    const d = data.curve(1 / 3, 1 / 3)
    // Same as above, but with a margin of 0.1 on both ends
    const d = data.curve(1 / 3, 1 / 3, 0.1)
    // Returns true if the offset is in range and false if it isn't
    const e = data.visible(2 / 3, 1 / 3)
    // The visible function can also receive a margin
    const f = data.visible(2 / 3, 1 / 3, 0.1)
    */
  })

  function clickHandler(e, val){
    setActive(val)
  }


  return (
    
         <Scroll html style={{ width: '100%' }} ref={scrollArea} >

          {mode != 'grid' &&
              <>
            
              <div className="block wide-panel">
                <div className="jumbo">
                  <Lottie 
                    options={defaultOptions}
                    height={400}
                    width={400}
                    className="lottie-logo"
                  />
                  {/*
                  <img src={logo} className="main-logo" alt="Logo" />
                  
                  <h3>Home of the Great Big Feelings Series</h3>
                  */}
                </div>
              </div>

              <div className="block wide-panel book-song-panel">
               {/* <ActivityPanel2 setCursor={setCursor} /> */}
                <ActivityPanel 
                      panelColor='blue'
                      //  mainImage='/assets/images/Booklaunch-wpm.jpg'
                        mainImage='/assets/images/StarShades-poster_img-v4-lrg.jpg'
                        mainUrl='https://star-shades.waypastbooks.com'
                        mainUrlTarget='_self'
                        btnOneUrl='https://star-shades.waypastbooks.com'
                        btnOneUrlTarget='_self'
                        btnOneMsg='Play the Star Shades Interactive Game!'
                       
                  />
              </div>


              <div className="block wide-panel book-song-panel">
               {/* <ActivityPanel2 setCursor={setCursor} /> */}
                <ActivityPanel 
                  setCursor={setCursor} 
                  panelColor='green'
                  mainImage='/assets/images/MeanBookExtensionAnnoucement.jpg'
                  mainUrl='#'
                  mainUrlTarget='_self'
                  btnOneUrl='/fun/WayPastMean_BasketballActivitySheet.pdf'
                  btnOneUrlTarget='_self'
                  btnOneMsg='Take a Shot!'
                  btnTwoUrl='/fun/WayPastMean_MyMeTeeWorksheet.pdf'
                  btnTwoMsg='My Mee Tee!'
                  btnTwoMode='play'
                  btnTwoUrlTarget='_self'
                  btnThreeUrl='/fun/WayPastMean_ConeOfCaringWorksheet.pdf'
                  btnThreeMsg='Cone of Caring'
                  btnThreeUrlTarget='_self'
                  btnThreeMode='play'
                  
                  />
              </div>

            

              <div className="block wide-panel book-song-panel">
               {/* <ActivityPanel2 setCursor={setCursor} /> */}
                <ActivityPanel 
                  setCursor={setCursor} 
                  panelColor='purple'
                //  mainImage='/assets/images/Booklaunch-wpm.jpg'
                  mainImage='/assets/images/MixedEmotions-poster_img-v4-lrg.jpg'
                  mainUrl='https://mixed-emotions.waypastbooks.com'
                  mainUrlTarget='_self'
                  btnOneUrl='https://mixed-emotions.waypastbooks.com'
                  btnOneUrlTarget='_self'
                  btnOneMsg='Play the Mixed Emotions Interactive Game!'
                  btnTwoUrl='/fun/WayPastBooks_MixedEmotions_Printable_Card_Deck.pdf'
                  btnTwoMsg='Or Download the Mixed Emotions Card Games!'
                 
                  
                  
                  />
              </div>

{/*
              <div className="block wide-panel book-song-panel">
          
                <ActivityPanel 
                  setCursor={setCursor} 
                  panelColor='purple'
                  mainImage='/assets/images/WPB_WebsitePromoOctober2023.jpg'
                  mainUrl='/fun-and-games'
                  mainUrlTarget='_self'
                  btnOneUrl='/lesson-plans'
                  btnOneUrlTarget='_self'
                  btnOneMsg='Choose a Lesson Plan!'
                  btnThreeUrl='/fun-and-games'
                  btnThreeMsg='More Fun and Games!'
                  
                  />
              </div>
                */}
            {/*
              <div className="block wide-panel book-song-panelX">
              <InViewMonitor
                 //classNameNotInView='video-holder'
                 //classNameInView='video-holder colorize blue'
                 classNameNotInView='content-holder'
                  classNameInView='content-holder bsc colorize purple' // fadeInLeft, or fadeInRight
                  toggleClassNameOnInView={true}
                  
                >

                
                 
                    <A href="/fun-and-games">
                    <div className="image-holder img-holder-bsc clickable" 
                    //  onClick={ ()=> {
                       // window.open("https://www.instagram.com/waypastbooks/", "_blank")
                      // window.location.href = "https://waypastbooks.com/fun-and-games";
                     // }}
                      onMouseEnter={() => setCursor('', false)} 
                      onMouseLeave={() => setCursor('', true)} 
                      >
                          <img src="/assets/images/WPB_WebsitePromoOctober2023.jpg" />

                        </div>
                        </A>
                      <div className="buttonContainer">
                     
                           <A href="/lesson-plans">
                          <div
                          // onMouseEnter={() => setCursor('Print your own Deck of Cards!', false)} 
                            //onMouseEnter={() => setCursor('Download The Make It Great Worksheet!', false)} 
                            onMouseEnter={() => setCursor('Choose a Lesson Plan!', false)} 
                            onMouseLeave={() => setCursor('', true)} 
                            onClick={() => {
                              //navToMore();
                             // navigate('/lesson-plans');
                             // setIsOverlayVisible(true)

                            }}
                            //className="roundButton printBtn" 
                            //className="roundButton downloadBtn"
                            className="roundButton playBtn"
                            style={{
                              backgroundColor: '#fcaf17'
                            }}
                            />
                          </A>

                          <A href="/fun-and-games">
                          <div
                          // onMouseEnter={() => setCursor('Print your own Deck of Cards!', false)} 
                            onMouseEnter={() => setCursor("More Fun and Games!", false)} 
                            onMouseLeave={() => setCursor('', true)} 
                           // className="roundButton printBtn"
                            className="roundButton playBtn"
                            style={{
                              backgroundColor: '#fdde76'
                            }}

                            
                            
                            />
                          </A>
                      </div>

             
                ////////////////////////
                      <YouTube 
                        //videoId="OZU1nrEB9GA" 
                        videoId = "YIb6T3A2E64"
                      // videoId={youTubeArr[0][0]}
                        opts={opts} 
                      
                        onPlay={(e) => clickHandler(e, true)}
                        onEnd={(e) => clickHandler(e, false)}
                        className={"roundedVideo "+(active ? "playing" : "stopped")}
                        />
                

                        <div className="buttonContainer">
                        <A href="/videos">
                          <div 
                            onMouseEnter={() => setCursor('See more videos...', false)} 
                            onMouseLeave={() => setCursor('', true)} 
                            //onClick={(e) => menuClick(e, 'video') } 
                            className="roundButton plusBtn" />

                        </A>
                        </div>
                //////////////////////////

                  </InViewMonitor>
              </div>

                          */}
            
            
              

                  {/* edit 4/17...
              <div className="block wide-panel">
                <ActivityPanel 
                  setCursor={setCursor} 
                  panelColor='purple'
                  mainImage='/assets/images/drawdogs-600.gif'
                  mainUrl='/game/drawdogs'
                  mainUrlTarget='_self'
                  btnOneUrl='/game/drawdogs'
                  btnOneUrlTarget='_self'
                  btnOneMsg='Follow along and Draw Your Own Dogs!'
                  btnTwoUrl='/fun/DrawYourOwnDogs_Worksheet.pdf'
                  btnTwoMsg='Download the Draw Your Own Dogs activity sheet!'
                  
                  />
              </div>
              */}
            
            {/*
              <div className="block wide-panel">
                  <InViewMonitor
                    classNameNotInView='video-holder'
                    classNameInView='video-holder colorize purple' // fadeInLeft, or fadeInRight
                    toggleClassNameOnInView={true}
                  >
                      <YouTube 
                        //videoId="1qr9WwxR72k" 
                       videoId={youTubeArr[1][0]}
                      //  videoId = "g-2EbmdzxLE"
                        opts={opts} 
                      
                      //  onPlay={(e) => clickHandler(e, true)}
                      //  onEnd={(e) => clickHandler(e, false)}
                        className={"roundedVideo "+(active ? "playing" : "stopped")}
                        />

                        <div className="buttonContainer">
                          <A href="/videos">
                            <div 
                              onMouseEnter={() => setCursor('See more videos...', false)} 
                              onMouseLeave={() => setCursor('', true)} 
                              //onClick={() => menuClick('video') } 
                              className="roundButton plusBtn" />

                          </A>
                        </div>

                  </InViewMonitor>
              </div>
              */}
            
              <div className="block wide-panel">
                <div className="book-info" id="books" >
                    <InViewMonitor

                      toggleClassNameOnInView={true}
                      intoViewMargin='100px'
                     // onInView={triggerBookViewAction}
                      useInviewMonitor={isMobile}
                      
                    >
                    
                    
                      <Slides 
                      //bookIndex={bookIndex}
                      // setHover={(e) => setCursor(e)} 
                        
                      />
                   
                
                  
                </InViewMonitor>
                </div>
              </div>

              <div className="block wide-panel ">
                <div className="book-info lefty" id="books2" >
                    <InViewMonitor

                      toggleClassNameOnInView={true}
                      intoViewMargin='100px'
                    //  onInView={triggerBookViewAction}
                      useInviewMonitor={isMobile}
                      
                    >

                    {/*  <h2 className="other">Other Books by <span className='author-span'>Hallee Adelman</span></h2> */}
                                          
                                          
                      <SlidesOther />
                     
                
                  
                </InViewMonitor>
                </div>
              </div>

              <div className="block glass">
                  <div className="intro-block">
                      <img src={logo} alt="Logo" />
                      <div className="social-links">
                        <p>Email us at <a href="mailTo:Connect@WayPastBooks.com" className="link" target="_blank">Connect@WayPastBooks.com</a></p>
                      </div>
                  </div>

                  <div className="content-block">
                    
                      <div className="more-links"
                        onMouseEnter={() => setCursor('', false)} 
                        onMouseLeave={() => setCursor('', true)} 
                      > 
                     
                        
                     
                          <button className="large-btn" onClick={ ()=> {
                              //setIsOverlayVisible(true)
                              navigate('/lesson-plans');
                           } }>Download Way Past Books Lesson Plans
                          </button>
                        

                        <a href="https://www.instagram.com/waypastbooks/" target="_blank" >
                          <button className="large-btn">Follow Way Past Books on Instagram</button>
                        </a>

                        
                        

                        <a href="https://www.snapchat.com/unlock/?type=SNAPCODE&uuid=9ddb977fc8534715aaec4dddc828d472&metadata=01" target="_blank" >
                      
                        <button>Download the Snapchat Filter</button>
                        </a>

                      
                      <a href="https://halleeadelman.com/" target="_blank" >
                        <button>About the Author: Hallee Adelman</button>
                        </a>
                        <a href="https://www.sandradelaprada.com/" target="_blank" >
                        <button>About the Illustrator: Sandra de la Prada</button>
                        </a>
                        <a href="http://www.karenwall.com/" target="_blank" >
                        <button>About the Illustrator: Karen Wall</button>
                        </a>
                        <a href="http://julilustrador.blogspot.com/" target="_blank" >
                        <button>About the Illustrator: Josep Maria Juli</button>
                        </a>
                        <a href="https://www.darcymarc.com/" target="_blank" >
                        <button>About the Illustrator (Swag): Darcy Marcantonio</button>
                        </a>
                        

                        <a href="https://www.albertwhitman.com/" target="_blank" >
                          <button>About the Publisher: Albert Whitman & Co.</button>
                        </a>

                      

                      {/*  <button>Contact Us</button> */}
                    
                      


                        
                          
                      </div>
                  </div>
              </div>

              </>

                      }
            



         </Scroll>
   
    /*
    <div id="scroller" className={`scrollArea ${(mode==='grid') ? "grid-mode" : ""}`} ref={scrollArea} onScroll={onScroll} {...events}>
      <div className="gridSpacer" style={{ height: `${(gridHeight)}px`}} ref={gridSpacerRef}/>
      <div className="domContent" style={{ position: 'sticky', top: 0 }} ref={domContent} />
      <div className="spacer" style={{ height: `${((pages) * 100)}vh` }} ref={spacerRef} />
    </div>
  */
  )

}