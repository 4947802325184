import React, { useState, useEffect, useMemo, useRef, Suspense } from 'react'
import { Canvas, Dom, extend, useLoader, useThree, useFrame } from '@react-three/fiber'
import * as THREE from 'three';
import { useSpring, useSprings, a } from '@react-spring/three'
//import { Billboard, RoundedBox} from '@react-three/drei'
//import { Billboard } from '@react-three/drei/Billboard'
//import { RoundedBox } from '@react-three/drei/RoundedBox'

import {
  useAudioStore,
  useSceneStore,
} from '../store'


const ImagePanel = ({ type, currentColor, layout, url, args, ...props }) => {
    const texture = useMemo(() => new THREE.TextureLoader().load('/assets/images/'+url), [url]);

   
    const { mode, filterGrid} = useSceneStore(state => state)

    const mobile = window.innerWidth < 700;
  
  
    // yuck -- opacity as own spring, which doesn't work. Switching on and off now
  //  const [opacityState, setOpacityState] = useState(layout)
    const [hoverState, setHoverState] = useState(false);
    //const [filterState, setFilterState] = useState(null)

    //const [active, setActive] = useState(false)
    const [hovered, setHover] = useState(false)

  
/*
    useEffect(() => {
      console.log('filterGrid', filterGrid)
      console.log('type', type)
      console.log('mode', mode)
     // document.body.style.cursor = hoverState ? 'pointer' : 'move'
    }, [])
*/
    const { ...props2 } = useSpring({
      //  'material-color': (filterGrid === type && mode==='grid' ) ? 'white' : currentColor,
       // color: (filterGrid === type && mode==='grid' ) ? 'white' : currentColor,

        'material-color': (filterGrid === type && mode==='grid' ) ? '#ffffff' : currentColor,
       // color: currentColor,
       // pos: active ? [0, 0, 2] : [0, 0, 0],
     //   'material-opacity': hovered ? 1 : 0.25,
     scale: hovered ? [args[0]*1.1,args[1]*1.1,args[2]*1.1] : (filterGrid === type && !mobile) ? args : [args[0]*.75,args[1]*.75,args[2]*.75],
   //     scale: hovered ? [args[0]*1.1,args[1]*1.1,args[2]*1.1] : args ,
     //   rotation: active ? [THREE.Math.degToRad(180), 0, THREE.Math.degToRad(45)] : [0, 0, 0],
        config: { mass: 10, tension: 1000, friction: 300, precision: 0.00001 }
      })
    /*
    const { opa } = useSpring({
      opacity: (opacityState === 'grid') ? 1 : .3,
      config: { mass: 100, tension: 50, friction: 50 },
      //config: SPRING_CONFIG,
      // onRest: () => setIdle((state) => !state),
      // onStart: () => setIdle((state) => !state),
    });
    */
   /*
   const scaleSpring  = useSpring({
      scale: (hoverState) ? [args[0]*1.1, args[1]*1.1, args[2]*1.1] : args,
      config: { mass: 100, tension: 50, friction: 50 },
      //config: SPRING_CONFIG,
      // onRest: () => setIdle((state) => !state),
       onStart: () => {console.log('start scaleSpring')},
    } );
  
    useEffect(() => {
      //console.log('hoverstate', hoverState)
    }, [hoverState])
    */
  /*
    useEffect(() => {
      
     setOpacityState(layout)
    }, [layout])
  */
 /*
    useEffect(() => {
  
     setFilterState(filter)
    }, [filter])
*/
    return (
      <a.group {...props}>
        
      <a.sprite  
       // scale={(hoverState ? [args[0]*1.05,args[1]*1.05,args[2]*1.05] : args)}
        //scale={scaleSpring.scale}
        //onPointerOver={() => setHoverState(true)}
        //onPointerOut={() => setHoverState(false)}
        //onClick={e => setActive(!active)} 
        onPointerOver={e => {if (mode==='grid') setHover(true)}} 
        onPointerOut={e => {if (mode==='grid') setHover(false)}}
        {...props2}
      >
     
        <spriteMaterial attach="material" map={texture}  />
     
      {/*  <planeBufferGeometry attach="geometry" args={args} />
        <meshLambertMaterial attach="material">
          <primitive attach="map" object={texture} />
        </meshLambertMaterial>*/}
      </a.sprite>
      {/*
      <Billboard
        position={[4, -2, 0]}
        args={args}
      
        material-color={"blue"}
        map={texture}
        follow={true}
        lockX={false}
        lockY={false}
        lockZ={false}
      />
      */}
      {/*
      <RoundedBox
        position={[0, 0, -1]}
      //  material-color={'yellow'}
        args={[args[0]*1.1,args[1]*1.1,1]} // Width, Height and Depth of the box
        radius={2} // Border-Radius of the box
        smoothness={4} // Optional, number of subdivisions
        // {...meshProps} // All THREE.Mesh props are valid
        >
        <a.meshBasicMaterial attach="material" color={color}/>
        </RoundedBox>
      */}
      </a.group>
    );
  };

  export default ImagePanel;