import React, { useEffect, useState, useRef, useCallback} from 'react'
//import { render } from 'react-dom'
//import { animated, useTransition, useSpring, useChain, config } from 'react-spring'
import { animated, useTransition, useSpring,useSpringRef, useChain, config } from '@react-spring/web'
import { shadeColor } from "../utils"

import {
  useAudioStore,
  useSceneStore,
} from '../store'

/*

export default function MobileBug() {
  
  const { fullMenuVisible, messages, youTubeArr, domRef, scrollRef, events, mode, top, scrollPos, zoom, panel, sections, bookIndex, contentLayout, bkgdColor, loaded, bookState, showBug, shuffled, character, topic, talk} = useSceneStore(state => state)
  const { setFullMenuVisible, setFilterGrid, setDomRef,setScrollRef, setMode,setScene, setScrollPos, setBookState, setTopic, setHomeMessage, setBkgdColor, setCharacter, setTalk, setShuffled, setShowBug, setContentLayout, setBookIndex, setLoaded, setDefaultHeight } = useSceneStore(state => state.actions)
  */
export default function MobileBug({showBug, currentColor, activate, setActivate, setMenu, ...props}) {
  
  const { fullMenuVisible, bkgdColor} = useSceneStore(state => state)
  const { setFullMenuVisible } = useSceneStore(state => state.actions)

  const [open, set] = useState(true)
  const [bugColor, setBugColor] = useState(currentColor)
  const [currentCharacter, setCurrentCharacter] = useState(0);
  const [index, setIndex] = useState(0)

  function handleClick(){
    set(fullMenuVisible)
    setFullMenuVisible(!fullMenuVisible);
    
    onClick();
  }

  const onClick = useCallback(() => {
    
      setIndex(state => (state + 1) % 2);
      //set((open) => !open)
     // setMenu((open) => !open);
      //setActivate((open) => !open);

    //  setFullMenuVisible(!fullMenuVisible)
    
  }, [])

  const transRef = useSpringRef()
  const transitions = useTransition(index, {
    // trail: 300,
   // delay: 300*index,
    ref: transRef,
    unique: true,
     from: { transform: 'scale(0) rotate(0deg)' },
     enter: { transform: open ? 'scale(1) rotate(0deg)' : 'scale(1) rotate(315deg)'},
     leave: { transform: 'scale(0) rotate(0deg)' },
   })
 
   const springRef = useSpringRef()
   const { size, opacity, ...rest } = useSpring({
     ref: springRef,
     config: config.wobbly,
     from: { 
       size: '60px', 
       background: bkgdColor,
      // transform: 'rotateX(0)' 
      },
     to: { 
       size: open ? '44px' : '60px', 
       background: !open ? shadeColor('#8e4b8e',-20) : shadeColor(bkgdColor,-20),
      // transform: 'rotateX(33deg)' 
      }
   })
 

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(open ? [springRef, transRef] : [transRef, springRef], [0, open ? 0.1 : 0.2])
/*
  useEffect(() => {
  //  set(showBug)
    //  set(activate);
   /// set(fullMenuVisible)
   return function(){
      //Unsubscribe websocket channel
  };
  
  }, [fullMenuVisible])

  @react-spring/threeffect(() => {
   // setBugColor(currentColor)
  }, [currentColor])
*/
  const pages = [
    ({ style }) => <animated.div style={{ ...style}} className="more-menu"></animated.div>,
    ({ style }) => <animated.div style={{ ...style}} className="close-menu"></animated.div>,
  ]

  return (
    <div {...props} className="cursorBox mobileBug" onClick={handleClick}>
      <div className="cursorHolder">
      <animated.div className="cursorCircle" style={{ ...rest, width: size, height: size }} >
      <div className="simple-trans-main" >


{transitions((styles, i) => {
       const Page = pages[i]
        return <Page key={i+'pg'} style={styles}  />
     }
)}



          {/*transitions.map(({ item, props, key }) => {
            const Page = pages[item]
            return <Page key={key} style={props} />
          })*/}
        </div>
        </animated.div>
 
      </div>
    </div>
  )
}