import React, { useEffect, useState, useRef, useCallback } from 'react'
//import { render } from 'react-dom'

import { animated, useTransition, useSpring,useSpringRef, useChain, config } from '@react-spring/web'
import { shadeColor } from "../utils"

import {
  useAudioStore,
  useSceneStore,
} from '../store'

//import data from './data'
//import logoW from './W.png'
//import logoP from './logo-p.png'
//import logoB from './logo-b.png'

const pages = [
  ({ style }) => <animated.div style={{ ...style}} className="mad"></animated.div>,
  ({ style }) => <animated.div style={{ ...style}} className="sad"></animated.div>,
  ({ style }) => <animated.div style={{ ...style}} className="jealous"></animated.div>,
  ({ style }) => <animated.div style={{ ...style}} className="worried"></animated.div>,
  ({ style }) => <animated.div style={{ ...style}} className="angry"></animated.div>,
  ({ style }) => <animated.div style={{ ...style}} className="lonely"></animated.div>,
  ({ style }) => <animated.div style={{ ...style}} className="mad"></animated.div>,
]


//export default function CursorCircle({showBug, currentColor, character}) {
function CursorCircle() {
//const CursorCircle = React.forwardRef(({  showBug, currentColor, character, ...props}, ref) => {
  
  const { showBug, bkgdColor, character } = useSceneStore(state => state)
 

   // const [showBug, set] = useState(true)
  //const [bugColor, setBugColor] = useState(currentColor)
  //const [currentCharacter, setCurrentCharacter] = useState(0);
  const [index, setIndex] = useState(0)

  const onClick = useCallback(() => setIndex(state => (state + 1) % 4), [])

  const transitions = useTransition(index, {
   // trail: 100,
    from: { transform: 'translate3d(0,100%,0)' },
    enter: { transform: 'translate3d(0,0%,0)' },
    leave: { transform: 'translate3d(0,100%,0)' },
  })

  const springRef = useSpringRef()
  const { size, opacity, ...rest } = useSpring({
    ref: springRef,
    config: config.wobbly,
    from: { size: '80px', background: bkgdColor },
    to: { size: showBug ? '56px' : '80px', background: shadeColor(bkgdColor,-20) }
  })

  const transRef = useSpringRef()

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  useChain(showBug ? [springRef, transRef] : [transRef, springRef], [0, showBug ? 0.1 : 0.2])
/*
  useEffect(() => {
    set(showBug)
  }, [showBug])
*/

  useEffect(() => {
    setIndex(character)
  }, [character])
  
/*
  useEffect(() => {
    setBugColor(currentColor)
  }, [currentColor])
*/
  useEffect(() => {
    console.log('init cursor circle')
   }, [])

  

  return (
    <div className="cursorBox">
      <div className="cursorHolder clip-circle">
        <animated.div className="cursorCircle" style={{ ...rest, width: size, height: size }} />

        {/*
        {transitions.map(({ item, key, props }) => (
          <animated.div className="character clip-circle" key={key} style={{ ...props, width: item.width, marginTop: item.offset, background: item.css }} />
        ))}
        */}

       {/*
        <img className="character" src="/assets/images/cursor/characters-worried-300.png" />
        <img className="character" src="/assets/images/cursor/characters-mad-300.png" />
        <img className="character" src="/assets/images/cursor/characters-sad-300.png" />
        <img className="character" src="/assets/images/cursor/characters-jealous-300.png" />
       */}
       
        <div className="simple-trans-main" onClick={onClick}>
        {transitions(( props , item, key) => {
    
            const Page = pages[item]
            return <Page style={props} />
          })}

          {/* transitions.map(({ item, props, key }) => {
            const Page = pages[item]
            return <Page key={key} style={props} />
          }) */}
        </div>
        
 
      </div>
    </div>
  )
}
//})

export default CursorCircle;