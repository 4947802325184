// src/components/Overlay.js

import React, { useState, useEffect } from 'react';
import {
  useSceneStore,

} from '../store'
import GridItem from '../components/GridItem';
import CloseButton from '../components/CloseButton';



const RecipiesOverlay = () => {
 
  const { isCategoryOverlayVisible} = useSceneStore(state => state)
  const { setIsCategoryOverlayVisible} = useSceneStore(state => state.actions)

  
  useEffect(() => {
    setIsCategoryOverlayVisible(true);
      
  }, []);

  const toggleOverlay = () => {

    setIsCategoryOverlayVisible(false);
  };



  return (
    <div className={`overlay recipies-overlay ${isCategoryOverlayVisible ? 'active' : ''}`}>

      <CloseButton
            toggleOverlay={toggleOverlay}
        />
         <div className="overlay-content">
          <div className='overlay-headline'>
            <h2>Choose a Recipe</h2>
          </div>
          <div className="grid-content">

            

                <GridItem 
                  title="Mad Cake Recipe Video"
                  image="/assets/images/MadCakesRecipe-Thumbnail.jpg"
                  url="https://youtu.be/OW9tTd-nEAM"
                 // target="_blank"
                  orient="horiz"
                  type="video"
                />
                <GridItem 
                  title="Mad Cake Recipe Sheet"
                  image="/assets/images/MadCake-RecipeActivitySheet.jpg"
                  url="/assets/images/pdf/MadCake-RecipeActivitySheet01.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Worry Whip Recipe Video"
                  image="/assets/images/worrywhip-poster.jpg"
                  url="https://youtu.be/6aIoYWQB0T0"
                 // target="_blank"
                  orient="horiz"
                  type="video"
                />


                <GridItem 
                  title="Worry Whip Recipe Sheet"
                  image="/assets/images/WorryWhipRecipeSheet-poster.jpg"
                  url="/assets/images/pdf/WorryWhipRecipeSheet.pdf"
                  target="_blank"
                />
                <GridItem 
                  title="Way Past Afraid Storm Cookie Recipe"
                  image="/assets/images/WayPastAfraid_StormCookieRecipe_v2.jpg"
                  url="/assets/images/pdf/WayPastAfraid_StormCookieRecipe_v2.pdf"
                  target="_blank"
                />
               
            
          </div>
        </div>
      </div>

  );
};

export default RecipiesOverlay;